<!--
 * @Descripttion: 表格选择器组件
 * @version: 1.2
 * @Program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023/04/03
-->

<template>
	<el-popover placement="right" :visible="visible" :width="tableWidth+50" trigger="click" :disabled="disabled" @show="visibleChange">
		<template #reference>
			<el-button :type="buttonType" :size="size" :icon="buttonIcon" :circle="buttonCircle" @click="chooseButtonClick">{{buttonText}}</el-button>
		</template>
		<div class="ly-table-select__table" :style="{width: tableWidth+'px'}" v-loading="loading" v-if="visible">
			<div class="ly-table-select__header">
				<slot name="header" :form="formData" :submit="formSubmit"></slot>
			</div>
			<el-table ref="table" border :data="tableData" :size="size" :height="450" :highlight-current-row="!multiple" @row-click="click" @select="select" @select-all="selectAll">
				<el-table-column v-if="multiple" type="selection" width="45"></el-table-column>
				<el-table-column type="index" width="60" label="序号">
					<template #default="scope">
						<span v-text="getIndex(scope.$index)"></span>
					</template>
				</el-table-column>
				<slot></slot>
			</el-table>
			<Pagination :small="true" v-bind:child-msg="pageparm"  @callFather="callFather"></Pagination>
		</div>
		<div style="text-align: center; margin: 0">
			<el-button  @click="handleCloseClick" :size="size">关闭</el-button>
			<el-button  type="primary" @click="confirmClick" :size="size">确认选择</el-button>
		</div>
    </el-popover>
</template>

<script>
	import Pagination from "@/components/Pagination";
	import {dateFormats} from "@/utils/util";
	export default {
		components: {Pagination},
		props: {
			modelValue: null,
			successCode:{ type: Number, default: 2000 },//网络请求完成代码
			apiObj: { type: Function, default: null},//网络请求的实例
			params: { type: Object, default: () => {} },//网络请求的额外参数
			buttonText: { type: String, default: "" },
            buttonIcon: { type: String, default: "" },
            buttonType: { type: String, default: "primary" },
            buttonCircle: { type: Boolean, default: false },
			placeholder: { type: String, default: "请选择" },
			size: { type: String, default: "default" },
			multiple: { type: Boolean, default: false },
			disabled: { type: Boolean, default: false },
			tableWidth: {type: Number, default: 500},
			props: { type: Object, default: () => {} },
		},
		data() {
			return {
				loading: false,
				defaultValue: [],
				tableData: [],
				defaultProps: {
					label: 'label',
					value: 'id',
					page: 'page',
					pageSize:'limit',
				},
				formInline:{
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
				formData: {},
				visible:false,
			}
		},
		computed: {

		},
		watch: {
			modelValue:{
				handler(){
					this.defaultValue = this.modelValue
				},
				deep: true
			}
		},
		mounted() {
			this.defaultProps = Object.assign(this.defaultProps, this.props);
			// this.defaultValue =  this.modelValue
		},
		methods: {
			// 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
			callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
			checkDefaultValue(){
            	if(this.multiple && this.defaultValue == undefined){
            		this.defaultValue = []
				}
			},
			chooseButtonClick(){
            	this.$emit("click")
			},
			confirmClick(){
            	let choooseDataList = this.defaultValue
				if(choooseDataList.length>0){
					this.$emit('confirm',choooseDataList)
					this.handleCloseClick()
				}else{
					this.$message.warning("请勾选待选择项")
					return
				}
			},
			handleCloseClick(){
            	this.defaultValue = []
				this.visible = false
			},
			handleChooseClick(){
            	this.defaultValue = []
				this.visible = true
			},
			//表格显示回调
			visibleChange(){
				this.formInline.page = 1
				this.formData = {}
				this.getData()
			},
			//获取表格数据
			async getData(){
				this.loading = true;
				var reqData = {
					[this.defaultProps.page]: this.formInline.page,
					[this.defaultProps.pageSize]: this.formInline.limit,
				}
				Object.assign(reqData, this.params, this.formData)
				//存在时间日期范围处理
				if(reqData.timerange){
					reqData.beginAt=dateFormats(reqData.timerange[0],'yyyy-MM-dd hh:mm:ss');
            		reqData.endAt=dateFormats(reqData.timerange[1],'yyyy-MM-dd hh:mm:ss');
					delete reqData.timerange
				}
				this.apiObj()(reqData).then(res=>{
					this.loading = false;
					if(res.code == this.successCode) {
						this.tableData = res.data.data
						 this.pageparm.page = res.data.page;
						 this.pageparm.limit = res.data.limit;
						 this.pageparm.total = res.data.total;
					} else {
						this.$message.warning(res.msg)
					}
					//表格默认赋值
					// this.$nextTick(() => {
					// 	if(this.multiple){
					// 		this.checkDefaultValue()
					// 		this.defaultValue.forEach(row => {
					// 			var setrow = this.tableData.filter(item => item[this.defaultProps.value]===row[this.defaultProps.value] )
					// 			if(setrow.length > 0){
					// 				this.$refs.table.toggleRowSelection(setrow[0], true);
					// 			}
					// 		})
					// 	}else{
					// 		var setrow = this.tableData.filter(item => item[this.defaultProps.value]===this.defaultValue[this.defaultProps.value] )
					// 		this.$refs.table.setCurrentRow(setrow[0]);
					// 	}
					// 	this.$refs.table.setScrollTop(0)
					// })
				});
			},
			//插糟表单提交
			formSubmit(){
				this.formInline.page = 1
				this.getData()
			},
			//表格勾选事件
			select(rows, row){
            	this.checkDefaultValue()
				var isSelect = rows.length && rows.indexOf(row) !== -1
				if(isSelect){
					this.defaultValue.push(row)
				}else{
					this.defaultValue.splice(this.defaultValue.findIndex(item => item[this.defaultProps.value] == row[this.defaultProps.value]), 1)
				}
				this.$emit('update:modelValue', this.defaultValue);
				this.$emit('change', this.defaultValue);
			},
			//表格全选事件
			selectAll(rows){
            	this.checkDefaultValue()
				var isAllSelect = rows.length > 0
				if(isAllSelect){
					rows.forEach(row => {
						this.defaultValue.push(row)
					})
				}else{
					this.tableData.forEach(row => {
						var isHas = this.defaultValue.find(item => item[this.defaultProps.value] == row[this.defaultProps.value])
						if(isHas){
							this.defaultValue.splice(this.defaultValue.findIndex(item => item[this.defaultProps.value] == row[this.defaultProps.value]), 1)
						}
					})
				}
				this.$emit('update:modelValue', this.defaultValue);
				this.$emit('change', this.defaultValue);
			},
			click(row){
				if(this.multiple){
					//处理多选点击行
				}else{
					this.defaultValue = row
					this.$emit('update:modelValue', this.defaultValue);
					this.$emit('change', this.defaultValue);
				}
			},
		}
	}
</script>

<style scoped>
	.ly-table-select__table {padding:12px;}
</style>