/**
 * lycrud需要经常用到的数据对象/数组
 */
export default {
    // 启用 true/ 禁用 false
    STATUS_BOOL:[{ label: '启用', value: true }, { label: '禁用', value: false }],
    // 启用 1/ 禁用 0
    STATUS_NUMBER:[{ label: '启用', value: 1 }, { label: '禁用', value: 0 }],
    // 是 1/ 否 0
    WHETHER_NUMBER:[{ label: '是', value: 1 }, { label: '否', value: 0 }],
    // 是 true/ 否 false
    WHETHER_BOOL:[{ label: '是', value: true }, { label: '否', value: false }],
    // 是 true/ 否 false
    GOODS_UNIT:[{ label: '个', value: '个' }, { label: '件', value: '件' }, { label: '只', value: '只' }, { label: '套', value: '套' }, { label: '双', value: '双' }, { label: '袋', value: '袋' }, { label: '包', value: '包' }, { label: '卷', value: '卷' }, { label: '桶', value: '桶' }],
}