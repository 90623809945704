<template>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="50%" :before-close="handleClose" :loading="dialogLoading" @closed="closed">
        <el-table :data="tableData" border stripe>
            <el-table-column prop="no" label="货号" min-width="180" show-overflow-tooltip></el-table-column>
            <el-table-column prop="category_name" label="品类" min-width="120"></el-table-column>
            <el-table-column prop="image" label="商品图" min-width="130">
                <template #default="scope">
                    <el-image  :src=scope.row.image :preview-src-list="[scope.row.image]" :preview-teleported="true" style="width: 40px;height: 40px"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="spec" label="规格" min-width="100"></el-table-column>
            <el-table-column prop="nums" label="上货数量" width="120"></el-table-column>
            <el-table-column prop="price" label="出库单价" width="180"></el-table-column>
            <el-table-column prop="total_mount" label="上货金额" width="180"></el-table-column>
        </el-table>
        <template #footer>
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleClose">确定</el-button>
        </template>
    </ly-dialog>
</template>

<script setup>
    import {ref} from 'vue'
    import {psiShopingoodsorderGoodsList} from "@/api/api";
    import { ElMessage } from 'element-plus';
    import LyDialog from "@/components/dialog/dialog";
    const emit = defineEmits(["refreshData","closed"])

    let dialogVisible = ref(false)
    let dialogLoading = ref(false)
    let dialogTitle = ref("")
    let tableData = ref([])

    function handleClose() {
        dialogVisible.value = false
        loadingSave.value = false
        dialogVisible.value = false
        //emit('refreshData')
    }

    function closed() {
        emit('closed')
    }

    function moduleFn(item,flag) {
        dialogTitle.value=flag
        dialogVisible.value=true
        getData(item)
    }

    function getData(id) {
        dialogLoading.value = true
        psiShopingoodsorderGoodsList({id:id}).then(res=>{
            dialogLoading.value = false
            if(res.code == 2000){
                tableData.value = res.data.data
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }
    defineExpose({ moduleFn })
</script>

<style scoped>

</style>