<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left">
                <el-form-item label="店铺" prop="shop" v-if="!(roleKey.includes('shop') && roleKey.length<2)">
                    <el-select v-model="formInline.shop" placeholder="请选择" clearable style="width: 180px" @change="search">
                        <el-option
                            v-for="item in shopList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="交款日期：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="handleEdit('','add')">新增</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="exportData" :disabled="loadingPage">导出</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="150" prop="no" label="交款编号"></el-table-column>
            <el-table-column min-width="180" prop="jk_time" label="交款日期"></el-table-column>
            <el-table-column min-width="120" prop="shop_name" label="店铺" show-overflow-tooltip></el-table-column>
             <el-table-column width="150" prop="myxc_usda" label="美元现钞(USDa)"></el-table-column>
            <el-table-column min-width="120" prop="usdrate" label="美元汇率"></el-table-column>
            <el-table-column min-width="120" prop="myzhk" label="美元折合K"></el-table-column>
            <el-table-column min-width="120" prop="kwcxc" label="克瓦查现钞(K)"></el-table-column>
            <el-table-column min-width="120" prop="mtnk" label="MTN（K）"></el-table-column>
            <el-table-column min-width="120" prop="hj_zhk" label="合计（折合K）"></el-table-column>
            <el-table-column min-width="120" prop="hj_shk" label="合计(实收K)"></el-table-column>
            <el-table-column min-width="120" prop="remark" label="备注"></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <ly-dialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="70%" top="10px" :before-close="handleDialogClose" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <div class="ly-title">
                    基础信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="交款日期" prop="jk_time">
                            <el-date-picker
                                style="width: 100%"
                                v-model="formData.jk_time"
                                type="datetime"
                                placeholder="选择时间"
                                format="YYYY-MM-DD HH:mm:ss"
                                value-format="YYYY-MM-DD HH:mm:ss"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="店铺" prop="shop">
                            <el-select v-model="formData.shop" placeholder="请选择" :disabled="shopDisable" filterable style="width: 100%">
                                <el-option
                                    v-for="item in shopList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="交款人" prop="jiaoker">
                            <el-input v-model="formData.jiaoker" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="收款人" prop="shouker">
                            <el-input v-model="formData.shouker" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    明细
                </div>
                <el-form-item>
                    <el-descriptions :column="3" border style="width: 100%">
                        <el-descriptions-item :span="1" align="center" label="美元现钞(USDa)"><el-input-number style="width: 100%;" v-model="formData.myxc_usda" placeholder="手动输入" @change="handleChangeCalc" :precision="2" :controls="false"></el-input-number></el-descriptions-item>
                        <el-descriptions-item :span="1" align="center" label="美元汇率"><el-input-number style="width: 100%;" v-model="formData.usdrate" placeholder="手动输入" @change="handleChangeCalc" :precision="2" :controls="false"></el-input-number></el-descriptions-item>
                        <el-descriptions-item :span="1" align="center" label="美元折合K"><el-input v-model="formData.myzhk" placeholder="系统计算" :disabled="true"></el-input></el-descriptions-item>
                        <el-descriptions-item :span="3" align="center" label="克瓦查现钞(K)"><el-input-number style="width: 100%;" v-model="formData.kwcxc" placeholder="手动输入" @change="handleChangeCalc" :precision="2" :controls="false"></el-input-number></el-descriptions-item>
                        <el-descriptions-item :span="3" align="center" label="MTN（K）"><el-input-number style="width: 100%;" v-model="formData.mtnk" placeholder="手动输入" @change="handleChangeCalc" :precision="2" :controls="false"></el-input-number></el-descriptions-item>
                        <el-descriptions-item :span="3" align="center" label="合计（折合K）">
                            <template #label>
                                <span style="color: red">*</span>
                                  合计（折合K）
                            </template>
                            <el-input v-model="formData.hj_zhk" placeholder="系统计算" :disabled="true"></el-input>
                        </el-descriptions-item>
                        <el-descriptions-item :span="3" align="center" label="合计(实收K)"><el-input v-model="formData.hj_shk" placeholder="系统计算" :disabled="true"></el-input></el-descriptions-item>
                    </el-descriptions>
                </el-form-item>
                <div class="ly-title">
                    备注
                </div>
                <el-form-item>
                    <el-input v-model="formData.remark" :rows="2" type="textarea" placeholder="请输入备注信息"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import Pagination from "@/components/Pagination";
    import {hasPermission, dateFormats, getStorage, lyParseParam2UrlParam} from "@/utils/util";
    import {psiShoppaymentorder,psiShop,psiShoppaymentorderAdd,psiShoppaymentorderExport} from '@/api/api'
    import useTableHight from '@/mixins/useTableHight';
    import { useRouter,useRoute } from 'vue-router'
    import LyDialog from "@/components/dialog/dialog";
    const route = useRoute()

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value)
    let loadingPage = ref(false)
    let loadingSave = ref(false)
    let isDialogShow = ref(false)
    let dialogTitle = ref("")
    let formData = ref({})
    let storeList = ref([])
    let categoryList = ref([])
    let shopList = ref([])
    let userName =getStorage("userName")
    let roleKey =getStorage("roleKey") || []
    let shopDisable = (getStorage("identity") == 0 || getStorage("identity") == 2)?false:true
    let rules = reactive({
        shop: [
            {required: true, message: '请选择店铺',trigger: 'blur'}
        ],
        jk_time: [
            {required: true, message: '请选择交款日期',trigger: 'blur'}
        ],
        jiaoker: [
            {required: true, message: '请输入交款人',trigger: 'blur'}
        ]
    })

    function handleDialogClose(){
        isDialogShow.value = false
        formData.value = {}
    }
    let formInline = ref({
        page: 1,
        limit: 10,
    })

    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let pageparm = ref({
        page: 1,
        limit: 10,
        total: 0
    })
    let tableData = ref([])
    let timers = ref([])
    let typeList = ref([])
    let statusList = ref([
        {id:3,name:'已完成'},
        {id:1,name:'待审核'},
        {id:2,name:'已拒绝'},
    ])

    function getShopList() {
        psiShop({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                shopList.value = res.data.data
            }
        })
    }

    //保留小数后两位（不四舍五入）
    function toFixed2(value) {
        if(!!value){
            // 截取当前数据到小数点后三位(保留后两位小数不四舍五入)
            let tempVal = parseFloat(value).toFixed(3)
            let realVal = tempVal.substring(0, tempVal.length - 1)
            return Number(realVal)
        }else{
            return 0
        }

    }
    function handleChangeCalc(){
        formData.value.myzhk = toFixed2(Number(formData.value.usdrate) * toFixed2(formData.value.myxc_usda))
        formData.value.hj_zhk = toFixed2(toFixed2(formData.value.myzhk)+toFixed2(formData.value.kwcxc) + toFixed2(formData.value.mtnk))
        formData.value.hj_shk = toFixed2(toFixed2(formData.value.kwcxc) + toFixed2(formData.value.mtnk))
    }
    //提交保存数据
    let rulesForm = ref(null)
    function submitData(){
        rulesForm.value.validate(obj=>{
            if(obj) {
                loadingSave.value=true
                let param = {
                    ...formData.value
                }
                if(formData.value.hj_zhk == undefined){
                    ElMessage.warning("必填内容不能为空，请填写后再提交")
                    loadingSave.value=false
                    return
                }
                psiShoppaymentorderAdd(param).then(res=>{
                    loadingSave.value=false
                    if(res.code ==2000) {
                        ElMessage.success(res.msg)
                        handleDialogClose()
                        getData()
                    } else {
                       ElMessage.warning(res.msg)
                    }
                })

            }
        })
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }
    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.value.page-1)*pageparm.value.limit + $index +1
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
     /** 添加表格操作 */
    function rowDel(row, index){
         formData.value.goodslist.splice(index, 1)
    }
    let showGoodsList = ref(false)
    let showPrint = ref(false)
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline.value = {
                page:1,
                limit: 10
            }
            pageparm.value={
                page: 1,
                limit: 10,
                total: 0
            }
            timers.value = []
            search()
        } else if(flag === 'add'){
            // if(roleKey.includes('shop')){
                dialogTitle = "添加店铺交款单"
                formData.value.shouker = userName
                formData.value.jk_time = dateFormats(new Date(),'yyyy-MM-dd hh:mm:ss')
                isDialogShow.value = true
                if(shopDisable){
                    formData.value.shop = getStorage("deptId")
                }
            // }else{
            //     ElMessage.warning("非店铺管理员无法增加")
            // }

        }
    }

    function callFather(parm) {
        formInline.value.page = parm.page
        formInline.value.limit = parm.limit
        getData()
    }
    function search() {
        formInline.value.page = 1
        formInline.value.limit = 10
        getData()
    }

    //获取列表
    async function getData(){
        loadingPage.value = true
        psiShoppaymentorder(formInline.value).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.data
                pageparm.value.page = res.data.page;
                pageparm.value.limit = res.data.limit;
                pageparm.value.total = res.data.total;
            }
        })
    }
    function timeChange(val){
        if (val) {
            formInline.value.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
            formInline.value.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
        } else {
            formInline.value.beginAt = null
            formInline.value.endAt = null
        }
        search()
    }
    // // 下载文件URL文件
    // function downloadFileURL(url) {
    //     var iframe =document.createElement("iframe")
    //     iframe.style.display ="none";
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    // }
    //
    // function exportData(){
    //     psiGoodsinstoreExport(formInline).then(res => {
    //          if(res.code ==2000) {
    //              downloadFileURL(res.data.data)
    //          }else{
    //               ElMessage.warning(res.msg)
    //          }
    //      })
    //
    // }
    //excel文件流下载
    function downloadExcelBlob(res) {
        let fileName = new Date().getTime() +".xlsx"
        let dispositionStr = res.headers["content-disposition"];
        if (dispositionStr == null || dispositionStr === "") {

        }else{
            // 获取文件名
            let dispositionArr = dispositionStr.split(";");
            fileName = decodeURIComponent(dispositionArr[1]);
            fileName = fileName.split("=")[1];
        }
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('导出成功')
    }
    //导出
    function exportData(row) {
        let params = {}
        let urlparams = lyParseParam2UrlParam(formInline.value)
        urlparams = !!urlparams?"?"+urlparams:''
        if(ids.value.length>0){
            params['ids'] = ids.value
        }
        loadingPage.value = true
        psiShoppaymentorderExport(urlparams,params).then(res => {
            loadingPage.value = false
            downloadExcelBlob(res)
        })
    }

    onMounted(()=>{
        getShopList()
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
    .lyTableCustomAppend{
        display: flex;
        align-items: center;
        border-bottom:var(--el-table-border);
    }
    .lytable-borderright{
        border-right: var(--el-table-border);
    }
    .lytable-borderleft{
        border-left: var(--el-table-border);
    }
</style>