<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left">
                <el-form-item label="店铺：">
                    <el-select size="default" v-model="formInline.shop" placeholder="请选择" clearable filterable style="width: 120px" @change="search">
                        <el-option
                                v-for="item in shopList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期范围：">
                    <el-date-picker
                        style="width: 280px"
                        @change="handleDatePickerChange"
                        v-model="dateranges"
                        type="daterange"
                        placeholder="请选择"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="exportDataFrontend">导出</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="handleEdit(formInline,'print')">打印</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>上期盘存额：{{orderstatics.tj_sqpce.toFixed(2) }}</li>
            <li>本期上货额：{{orderstatics.tj_bqshe.toFixed(2)}}</li>
            <li>本期退库额：{{orderstatics.tj_bqthe.toFixed(2)}}</li>
            <li>本期上交款：{{orderstatics.tj_bqsjk.toFixed(2)}}</li>
            <li>本期盘存额：{{orderstatics.tj_bqpce.toFixed(2)}}</li>
            <li>本期盈亏金额：{{orderstatics.tj_bqykje.toFixed(2)}}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="55"></el-table-column>-->
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column min-width="130" prop="no" label="货号"></el-table-column>
            <el-table-column min-width="100" prop="price" label="出库单价"></el-table-column>
            <el-table-column min-width="130" prop="sq_pcsl" label="上期盘存数量"></el-table-column>
            <el-table-column min-width="130" prop="bq_shsl" label="本期上货数量"></el-table-column>
            <el-table-column min-width="130" prop="bq_thsl" label="本期退库数量"></el-table-column>
            <el-table-column min-width="130" prop="bq_ypcsl" label="本期应盘存数量"></el-table-column>
            <el-table-column min-width="130" prop="bq_sjpcsl" label="本期实际盘存数量"></el-table-column>
            <el-table-column min-width="130" prop="bq_xssl" label="本期销售数量"></el-table-column>
            <el-table-column min-width="130" prop="bq_xsje" label="本期销售金额"></el-table-column>

        </el-table>
        <moduleShopPanCunStasticsPrint v-if="showPrint" ref="modulePrintFlag" @close = "showPrint = false"></moduleShopPanCunStasticsPrint>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import {hasPermission,dateFormats,getStorage} from "@/utils/util";
    import {psiShoppankuorderStastics,psiShop} from '@/api/api'
    import useTableHight from '@/mixins/useTableHight';
    import { useRouter,useRoute } from 'vue-router'
    import * as XLSX from 'xlsx';
    import FileSaver from "file-saver"
    import moduleShopPanCunStasticsPrint from "./components/moduleShopPanCunStasticsPrint"
    const route = useRoute()

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value,false)
    let loadingPage = ref(false)
    let loadingSave = ref(false)
    let isDialogShow = ref(false)
    let dateranges = ref([])
    let shopList = ref([])
    let dialogTitle = ref("")
    let formData = ref({})
    let userName =getStorage("userName")
    let orderstatics = ref({
        tj_sqpce:0.00,tj_bqshe:0.00,tj_bqthe:0.00,tj_bqsjk:0.00,tj_bqpce:0.00,tj_bqykje:0.00
    })
    let formInline = ref({
    })

    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let tableData = ref([])

    function getShopList() {
        psiShop({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                shopList.value = res.data.data
            }
        })
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
    let showPrint = ref(false)
    let modulePrintFlag =ref(null)
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline.value = {
            }
            dateranges.value = []
            search()
        }else if(flag == "print"){
            if(formInline.value.shop == undefined || formInline.value.endAt == undefined){
                ElMessage.warning("请选择店铺和日期范围")
                return
            }
            showPrint.value = true
            nextTick(()=>{
                modulePrintFlag.value.moduleFn(row,"打印")
            })
        }
    }

    function search() {
        getData()
    }
    function  handleDatePickerChange(picker) {
        formInline.value.beginAt = picker[0].toString()
        formInline.value.endAt = picker[1].toString()
        search()
    }
    //获取列表
    async function getData(){
        loadingPage.value = true
        psiShoppankuorderStastics(formInline.value).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.dataList
                orderstatics.value = res.data.orderstatics
            }
        })
    }
    // // 下载文件URL文件
    // function downloadFileURL(url) {
    //     var iframe =document.createElement("iframe")
    //     iframe.style.display ="none";
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    // }
    //
    // function exportData(){
    //     psiGoodsinstoreExport(formInline).then(res => {
    //          if(res.code ==2000) {
    //              downloadFileURL(res.data.data)
    //          }else{
    //               ElMessage.warning(res.msg)
    //          }
    //      })
    //
    // }
    //excel文件流下载
    function downloadExcelBlob(res) {
        let fileName = new Date().getTime() +".xlsx"
        let dispositionStr = res.headers["content-disposition"];
        if (dispositionStr == null || dispositionStr === "") {

        }else{
            // 获取文件名
            let dispositionArr = dispositionStr.split(";");
            fileName = decodeURIComponent(dispositionArr[1]);
            fileName = fileName.split("=")[1];
        }
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('导出成功')
    }
    // //导出
    // function exportData(row) {
    //     let params = {}
    //     if(ids.value.length>0){
    //         params = {
    //             ids:ids.value,
    //         }
    //     }
    //     loadingPage.value = true
    //     psiStockgoodsExport(params).then(res => {
    //         loadingPage.value = false
    //         downloadExcelBlob(res)
    //     })
    // }

    let tableref = ref(null)
    //前端直接导出(头部插入自定义数据)
    function exportDataFrontend(){
        //分页情况先显示所有数据(在第一页)
        var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
        const $table = tableref.value.$el
        const wb = XLSX.utils.table_to_book($table, {raw: true,origin:1});//table_to_book放入的是table 的DOM 节点,origin从第二行开始写入数据
        var ws = wb.Sheets.Sheet1
        // 需要合并的单元格
        var mergeArr=[]
        /**
        e: {//e结束
          c: 3,//结束列
          r: 4//结束行
        },
        s: {//s为开始
          c: 0,//开始列
          r: 0//可以看成开始行,实际是取值范围
        }
        */
        mergeArr.push({
           s: {r:0, c: 0},
           e: {r:0, c: 9}
        })
        ws['!merges'] = mergeArr//合并第一行数据
        var w_data = ['上期盘存额:'+orderstatics.value.tj_bqshe.toFixed(2)+' 本期上货额:'+orderstatics.value.tj_bqthe.toFixed(2)+' 本期退库额:'+orderstatics.value.tj_bqthe.toFixed(2)+' 本期上交款:'+orderstatics.value.tj_bqsjk.toFixed(2)+' 本期盘存额:'+orderstatics.value.tj_bqpce.toFixed(2)+' 本期盈亏金额:'+orderstatics.value.tj_bqykje.toFixed(2)]
        XLSX.utils.sheet_add_json(ws,[],{header:w_data ,origin: 0})//插入数据到头部
        // 每列不同宽度px
        let wscols = [
            { wch: 10 },
            { wch: 26 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
          ];
        ws["!cols"] = wscols;
        let wsrows = [{ hpx: 30 }];  // 设置第一行的高度px
        ws["!rows"] = wsrows;
        const wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
        });
        
        // 获取当前的时间戳，用来当文件名
        // let time = new Date().getTime()
        let time = "导出店铺盘存情况查询数据"
        try {
            FileSaver.saveAs(
              new Blob([wbout], { type: "application/octet-stream" }),
              `${time}.xlsx`
            ); //${time}是自己导出文件时的命名，随意
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout)
          }
        return wbout
    }

    onMounted(()=>{
        getShopList()
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
</style>