<template>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="796px" top="20px" :before-close="handleClose" :loading="dialogLoading" @closed="closed">
        <div style="display: flex;justify-content: right"><el-button type="primary" @click="handlePrint">打印</el-button></div>
        <div class="page_height use-get-height" id="pageHeight">
            <div style="display: flex;justify-content: center;font-size: 18px;line-height: 60px;" id="printTitle">店铺盘货情况查询</div>
            <div style="display: flex;justify-content: left;font-size: 15px;line-height: 30px;" ref="lycommonheader" id="msgTitle">
                <div>店铺：{{shopname}}</div>
                <div style="margin-left: 30px;">日期：{{tempItem.beginAt +"--" + tempItem.endAt}}</div>
            </div>
            <table class="ordertable" id="bottomDiv" border="1" align="center" cellspacing="0">
                <tr id="bottomTh">
                    <td style="width: 30px;" class="ordertableborder-right">序号</td>
                    <td class="ordertableborder-right">货号</td>
                    <td class="ordertableborder-right">出库单价</td>
                    <td class="ordertableborder-right">上期盘存数量</td>
                    <td class="ordertableborder-right">本期上货数量</td>
                    <td class="ordertableborder-right">本期退库数量</td>
                    <td class="ordertableborder-right">本期应盘存数量</td>
                    <td class="ordertableborder-right">本期实际盘存数量</td>
                    <td class="ordertableborder-right">本期销售数量</td>
                    <td class="ordertableborder-right">本期销售金额</td>
                </tr>
                <tr v-for="(itemd,indexd) in tableData" :key="indexd"  :id="`bottomTd_${itemd.id}`">
                    <td class="ordertableborder-right">{{indexd+1}}</td>
                    <td class="ordertableborder-right">{{itemd.no}}</td>
                    <td class="ordertableborder-right">{{itemd.price}}</td>
                    <td class="ordertableborder-right">{{itemd.sq_pcsl}}</td>
                    <td class="ordertableborder-right">{{itemd.bq_shsl}}</td>
                    <td class="ordertableborder-right">{{itemd.bq_thsl}}</td>
                    <td class="ordertableborder-right">{{itemd.bq_ypcsl}}</td>
                    <td class="ordertableborder-right"></td>
                    <td class="ordertableborder-right"></td>
                    <td class="ordertableborder-right"></td>
                </tr>
                <div id="pageMessage" class="page-message">
                </div>
            </table>
        </div>
        <!--要分页打印的虚拟dom-->
        <div class="page_div">
            <div ref="lyOrderPrint" class="print_div">
                <div v-for="(itemp, indexAll) in printData" :key="indexAll">
                    <div class="page_height">
                        <div style="display: flex;justify-content: center;font-size: 18px;line-height: 60px;">店铺盘货情况查询</div>
                        <div style="display: flex;justify-content: left;font-size: 15px;;line-height: 30px;" ref="lycommonheader">
                            <div>店铺：{{shopname}}</div>
                            <div style="margin-left: 30px;">日期：{{tempItem.beginAt +"--" + tempItem.endAt}}</div>
                        </div>
                        <table class="ordertable" border="1" align="center" cellspacing="0">
                            <tr>
                                <td style="width: 30px;" class="ordertableborder-right">序号</td>
                                <td class="ordertableborder-right">货号</td>
                                <td class="ordertableborder-right">出库单价</td>
                                <td class="ordertableborder-right">上期盘存数量</td>
                                <td class="ordertableborder-right">本期上货数量</td>
                                <td class="ordertableborder-right">本期退库数量</td>
                                <td class="ordertableborder-right">本期应盘存数量</td>
                                <td class="ordertableborder-right">本期实际盘存数量</td>
                                <td class="ordertableborder-right">本期销售数量</td>
                                <td class="ordertableborder-right">本期销售金额</td>
                            </tr>
                            <tr v-for="(itemd,indexd) in itemp.splitData" :key="indexd">
                                <td class="ordertableborder-right">{{Number(printData[0].splitData.length*indexAll)+indexd+1}}</td>
                                <td class="ordertableborder-right">{{itemd.no}}</td>
                                <td class="ordertableborder-right">{{itemd.price}}</td>
                                <td class="ordertableborder-right">{{itemd.sq_pcsl}}</td>
                                <td class="ordertableborder-right">{{itemd.bq_shsl}}</td>
                                <td class="ordertableborder-right">{{itemd.bq_thsl}}</td>
                                <td class="ordertableborder-right">{{itemd.bq_ypcsl}}</td>
                                <td class="ordertableborder-right"></td>
                                <td class="ordertableborder-right"></td>
                                <td class="ordertableborder-right"></td>
                            </tr>

                        </table>
                        <div class="page-message">
                            {{ Number(indexAll)+1 }} / {{ totalPage }}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ly-dialog>
</template>

<script setup>
    import {ref,reactive,onMounted,nextTick} from 'vue'
    import {psiShoppankuorderStastics} from "@/api/api";
    import { ElMessage } from 'element-plus';
    import LyDialog from "@/components/dialog/dialog";
    import lyprint from '@/utils/lyprint'
    import {deepClone} from "@/utils/util";

    const emit = defineEmits(["refreshData","closed"])

    let dialogVisible = ref(false)
    let dialogLoading = ref(false)
    let loadingSave = ref(false)
    let dialogTitle = ref("")
    let tableData = ref([])
    let shopname = ref("")
    let tempItem = ref({})
    //分页打印关键参数
    let paperHeight = ref(undefined)//1123
    let printData = ref([])//处理后要分页的data数据
    let totalPage = ref(1)//总页数


    function handleClose() {
        dialogVisible.value = false
        loadingSave.value = false
        dialogVisible.value = false
        //emit('refreshData')
    }

    function closed() {
        emit('closed')
    }

    function moduleFn(item,flag) {
        dialogTitle.value=flag
        dialogVisible.value=true
        tempItem.value = item
        getData(item)
    }

    let lyOrderPrint = ref(null)
    function handlePrint() {
        lyprint(lyOrderPrint.value)
    }

    function getData(formInline) {
        dialogLoading.value = true
        psiShoppankuorderStastics(formInline).then(res=>{
            dialogLoading.value = false
            if(res.code == 2000){
                tableData.value = res.data.dataList
                //模拟数据
                // for(var i = 0; i <= 60; i++){
                //     tableData.value.push(
                //         res.data.dataList[0]
                //     )
                // }
                shopname.value = res.data.shopname
                // printPage()
                nextTick(() => {
                    paperHeight.value = document.getElementById('pageHeight').clientHeight
                    console.log(paperHeight.value)
                    countPage() //计算分割点
                })
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }

    // 计算分割点
    const countPage = () => {
        let printTitle = document.getElementById('printTitle')
        let printTitleH = printTitle.clientHeight

        let msgTitle = document.getElementById('msgTitle')
        let msgTitleH = msgTitle.clientHeight

        let bottomTh = document.getElementById('bottomTh')
        let bottomThH = bottomTh.clientHeight

        let bottomDiv = document.getElementById(`bottomDiv`)
        let bottomDivH = bottomDiv.clientHeight

        let pageMessage = document.getElementById('pageMessage')
        let pageMessageH = pageMessage.clientHeight
        // 动态表格内容的高度 = A4纸高度 - 打印标题高度 - 小标题高度*2 - 上面表格高度 - 下面表格的表头高度 - 页码高度
        let surplusHeight =
            paperHeight.value -
            printTitleH -
            msgTitleH -
            msgTitleH -
            bottomThH -
            pageMessageH

        // 动态表格内容的高度 >= 实际下面表格的高度，表示实际下面表格的高度没有超过一页，不分页
        // 动态表格内容的高度 < 实际下面表格的高度，表示实际下面表格的高度超过一页了，根据计算的动态表格高度进行分页
        if (surplusHeight < bottomDivH) {
            // 循环列表数组，获取每行的高度相加，当高度和大于计算的动态表格高度，则此处分页
            let sum = 0
            for(var i = 0; i < tableData.value.length; i++){
                let item_ = tableData.value[i]
                let bottomTd = document.getElementById(`bottomTd_${item_.id}`)
                let bottomTdH = bottomTd.clientHeight
                sum += bottomTdH
                // 行高之和 > 计算的高度，表示已经超出了，在上一行就应该分页
                if (sum > surplusHeight) {
                    //标记要分页的行数
                    let splitIndex = i
                    totalPage.value = Math.ceil(tableData.value.length / splitIndex)
                    for(var s=0;s<tableData.value.length;s += splitIndex){
                        printData.value.push({splitData:tableData.value.slice(s,s + splitIndex)})
                    }
                    break
                }
            }

        }else{
            totalPage.value = 1
            printData.value.push({splitData:tableData.value})
        }
    }

    defineExpose({ moduleFn })
</script>

<style scoped>
    @page {size: auto;margin: 0mm 8mm;}
    .ordertable{
        width: 100%;
        border-spacing: 0;
        margin: 0 auto;
        border-left: 1px solid #ddd;
        /*border-right: 1px solid #ddd;*/
        border-top: 1px solid #ddd;
        font-size:10px;
        text-align: center;
    }
    .ordertable td{
        text-align: center;
        vertical-align: middle;
        font-size: 10px;
        border-bottom: 1px solid #ddd;
        word-break: break-all;
        padding: 0;
        height: 30px;
    }
    .ordertableborder-right{
        border-right: 1px solid #ddd;
    }
    .page_height {
        width: 200mm;
        height: 297mm;
        box-sizing: border-box;
        margin-bottom: 85px;
        position: relative;
    }
    .use-get-height {
        position: absolute;
        top: 0;
        z-index: -1;
        opacity: 0;
    }
    .print_div {
        page-break-before: always;
        page-break-after: avoid;
        page-break-inside: avoid;
        margin: 0 auto;
    }
    .page_div {
        height: 85%;
        overflow: auto;
        display: flex;
    }
    .page-message {
        text-align: center;
        page-break-before: avoid;
        page-break-after: always;
        page-break-inside: avoid;
        position: absolute;
        bottom: 0;
        /*left: 49%;*/
        right:0%;
    }
</style>