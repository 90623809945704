<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left" label-width="auto">
                <el-form-item label="支出日期：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="handleEdit('','add')">新增</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="exportDataFrontend" :disabled="loadingPage">导出</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="55"></el-table-column>-->
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" prop="no" label="支出编号"></el-table-column>
            <el-table-column min-width="180" prop="out_time" label="支出时间"></el-table-column>
            <el-table-column min-width="130" prop="remark" label="摘要" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="100" prop="heiren_gz" label="黑人工资"></el-table-column>
            <el-table-column min-width="100" prop="xiaofei" label="小费"></el-table-column>
            <el-table-column min-width="100" prop="shicai" label="食材费"></el-table-column>
            <el-table-column min-width="100" prop="chaoshi" label="超市费"></el-table-column>
            <el-table-column min-width="100" prop="bangong" label="办公费"></el-table-column>
            <el-table-column min-width="120" prop="bar9114" label="BAR9114车费"></el-table-column>
            <el-table-column min-width="120" prop="bar4466" label="BAD4466车费"></el-table-column>
            <el-table-column min-width="120" prop="baa5028" label="BAA5028车费"></el-table-column>
            <el-table-column min-width="130" prop="alt3122" label="ALT3122车费"></el-table-column>
            <el-table-column min-width="130" prop="weixiu" label="维修费"></el-table-column>
            <el-table-column min-width="130" prop="dian" label="电费"></el-table-column>
            <el-table-column min-width="130" prop="yehuaqi" label="液化气费"></el-table-column>
            <el-table-column min-width="130" prop="yun" label="运费"></el-table-column>
            <el-table-column min-width="130" prop="yun_usd" label="运费(USD)"></el-table-column>
            <el-table-column min-width="130" prop="dianpu" label="店铺费用"></el-table-column>
            <el-table-column min-width="130" prop="qita" label="其他费用"></el-table-column>
            <el-table-column min-width="130" prop="qita_usd" label="其他费用(USD)"></el-table-column>
            <el-table-column min-width="140" prop="kwc_hj" label="克瓦查支出合计"></el-table-column>
            <el-table-column min-width="140" prop="meiyuan_hj" label="美元支出合计"></el-table-column>
            <el-table-column min-width="140" prop="kwccyh" label="克瓦查存银行"></el-table-column>
            <el-table-column min-width="140" prop="meiyuancyh" label="美元存银行"></el-table-column>
            <el-table-column min-width="140" prop="zjkwcjs" label="总计克瓦查减少"></el-table-column>
            <el-table-column min-width="140" prop="zjmyjs" label="总计美元减少"></el-table-column>
            <template v-for="(item,index) in payOutList">
                <el-table-column min-width="140" :label="item.name">
                    <template #default="scope">
                        <div v-if="scope.row.dynamic_fields.length<=0">0.00</div>
                        <div v-else v-for="(ditem,dindex) in scope.row.dynamic_fields">
                            <span v-if="!!ditem && !!ditem.id && ditem.id == item.id">{{toFixed2(ditem.value)}}</span>
                            <span v-else-if="payOutList.findIndex(fitem => fitem.id=== ditem.id) == -1">0.00</span>
                        </div>
                    </template>
                </el-table-column>
            </template>

        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <ly-dialog v-model="isDialogShow"  :title="dialogTitle" width="60%" top="10px" :before-close="handleDialogClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <div class="ly-title">
                    基础信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="支出日期" prop="out_time">
                            <el-date-picker
                                style="width: 100%"
                                v-model="formData.out_time"
                                type="datetime"
                                placeholder="选择时间"
                                format="YYYY-MM-DD HH:mm:ss"
                                value-format="YYYY-MM-DD HH:mm:ss"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="经手人" prop="jingshou">
                            <el-input v-model="formData.jingshou" placeholder="请输入" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    明细
                </div>
                <el-form-item prop="remark">
                    <el-input v-model="formData.remark" type="textarea" :rows="2" placeholder="请输入"></el-input>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="黑人工资" prop="heiren_gz">
                            <el-input-number style="width: 100%;" v-model="formData.heiren_gz" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="小费" prop="xiaofei">
                            <el-input-number style="width: 100%;" v-model="formData.xiaofei" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="食材费" prop="shicai">
                            <el-input-number style="width: 100%;" v-model="formData.shicai" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="超市费" prop="chaoshi">
                            <el-input-number style="width: 100%;" v-model="formData.chaoshi" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="办公费" prop="bangong">
                            <el-input-number  style="width: 100%;" v-model="formData.bangong" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="BAR9114车费" prop="bar9114">
                            <el-input-number style="width: 100%;" v-model="formData.bar9114" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="BAD4466车费" prop="bar4466">
                            <el-input-number style="width: 100%;" v-model="formData.bar4466" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="BAA5028车费" prop="baa5028">
                            <el-input-number style="width: 100%;" v-model="formData.baa5028" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="ALT3122车费" prop="alt3122">
                            <el-input-number style="width: 100%;" v-model="formData.alt3122" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="维修费" prop="weixiu">
                            <el-input-number style="width: 100%;" v-model="formData.weixiu" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="电费" prop="dian">
                            <el-input-number style="width: 100%;" v-model="formData.dian" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="液化气费" prop="yehuaqi">
                            <el-input-number style="width: 100%;" v-model="formData.yehuaqi" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="运费" prop="yun">
                            <el-input-number style="width: 100%;" v-model="formData.yun" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运费(USD)" prop="yun_usd">
                            <el-input-number style="width: 100%;" v-model="formData.yun_usd" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="店铺费用" prop="dianpu">
                            <el-input-number style="width: 100%;" v-model="formData.dianpu" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="其他费用" prop="qita">
                            <el-input-number style="width: 100%;" v-model="formData.qita" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="其他费用(USD)" prop="qita_usd">
                            <el-input-number style="width: 100%;" v-model="formData.qita_usd" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12" v-for="(fitem,findex) in formData.dynamic_fields">
                        <el-form-item :label="fitem.name">
                            <el-input-number style="width: 100%;" v-model="fitem.value" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalcDynamic(fitem.value)"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="克瓦查支出合计" prop="kwc_hj">
                            <el-input-number style="width: 100%;" v-model="formData.kwc_hj" placeholder="系统计算" :precision="2" :controls="false" :disabled="true"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="美元支出合计" prop="meiyuan_hj">
                            <el-input-number style="width: 100%;" v-model="formData.meiyuan_hj" placeholder="系统计算" :precision="2" :controls="false" :disabled="true"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="克瓦查存银行" prop="kwccyh">
                            <el-input-number style="width: 100%;" v-model="formData.kwccyh" placeholder="请输入" @change="handleChangeCalc" :precision="2" :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="美元存银行" prop="meiyuancyh">
                            <el-input-number style="width: 100%;" v-model="formData.meiyuancyh" placeholder="请输入" @change="handleChangeCalc" :precision="2" :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="总计克瓦查减少" prop="zjkwcjs">
                            <el-input-number style="width: 100%;" v-model="formData.zjkwcjs" placeholder="系统计算" :precision="2" :controls="false" :disabled="true"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="总计美元减少" prop="zjmyjs">
                            <el-input-number style="width: 100%;" v-model="formData.zjmyjs" placeholder="系统计算" :precision="2" :controls="false" :disabled="true"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import Pagination from "@/components/Pagination";
    import {hasPermission,dateFormats,getStorage} from "@/utils/util";
    import {psiCashoutorder,psiCashoutorderAdd,psiCashoutorderExport,psiPayOut} from '@/api/api'
    import useTableHight from '@/mixins/useTableHight';
    import { useRouter,useRoute } from 'vue-router'
    import LyDialog from "@/components/dialog/dialog";
    import LyTableSelect from "@/components/lyTableSelect";
    import * as XLSX from "xlsx";
    import FileSaver from "file-saver"
    const route = useRoute()

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value)
    let loadingPage = ref(false)
    let loadingSave = ref(false)
    let isDialogShow = ref(false)
    let dialogTitle = ref("")
    let formData = ref({})
    let storeList = ref([])
    let categoryList = ref([])
    let userName =getStorage("userName")
    let rules = reactive({
        out_time: [
            {required: true, message: '请选择支出日期',trigger: 'blur'}
        ],
        kwc_hj: [
            {required: true, message: '请填写费用',trigger: 'blur'}
        ],
        meiyuan_hj: [
            {required: true, message: '请填写费用',trigger: 'blur'}
        ],
    })
    function handleDialogClose(){
        isDialogShow.value = false
        formData.value = {}
    }
    let formInline = ref({
        page: 1,
        limit: 10,
    })

    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let pageparm = ref({
        page: 1,
        limit: 10,
        total: 0
    })
    let tableData = ref([])
    let timers = ref([])
    let typeList = ref([])
    let statusList = ref([
        {id:3,name:'已完成'},
        {id:1,name:'待审核'},
        {id:2,name:'已拒绝'},
    ])

    //提交保存数据
    let rulesForm = ref(null)
    function submitData(){
        rulesForm.value.validate(obj=>{
            if(obj) {
                loadingSave.value=true
                let param = {
                    ...formData.value
                }
                psiCashoutorderAdd(param).then(res=>{
                    loadingSave.value=false
                    if(res.code ==2000) {
                        ElMessage.success(res.msg)
                        handleDialogClose()
                        getData()
                    } else {
                       ElMessage.warning(res.msg)
                    }
                })

            }
        })
    }
    //保留小数后两位（不四舍五入）
    function toFixed2(value) {
        if(!!value){
            // 截取当前数据到小数点后三位(保留后两位小数不四舍五入)
            let tempVal = parseFloat(value).toFixed(3)
            let realVal = tempVal.substring(0, tempVal.length - 1)
            return Number(realVal)
        }else{
            return 0
        }

    }
    function handleChangeCalcDynamic(value) {
        var hjdy = 0
        for(var i=0;i<formData.value.dynamic_fields.length;i++){
            hjdy = hjdy + toFixed2(formData.value.dynamic_fields[i].value)
        }
        formData.value.kwc_hj =hjdy + toFixed2(toFixed2(formData.value.heiren_gz) + toFixed2(formData.value.xiaofei) + toFixed2(formData.value.shicai) + toFixed2(formData.value.chaoshi) + toFixed2(formData.value.bangong) + toFixed2(formData.value.bar9114) + toFixed2(formData.value.bar4466) + toFixed2(formData.value.baa5028) + toFixed2(formData.value.alt3122) + toFixed2(formData.value.weixiu) + toFixed2(formData.value.dian) + toFixed2(formData.value.yehuaqi) + toFixed2(formData.value.yun) + toFixed2(formData.value.dianpu) + toFixed2(formData.value.qita))
    }
    function handleChangeCalc(){
        formData.value.kwc_hj = toFixed2(toFixed2(formData.value.heiren_gz) + toFixed2(formData.value.xiaofei) + toFixed2(formData.value.shicai) + toFixed2(formData.value.chaoshi) + toFixed2(formData.value.bangong) + toFixed2(formData.value.bar9114) + toFixed2(formData.value.bar4466) + toFixed2(formData.value.baa5028) + toFixed2(formData.value.alt3122) + toFixed2(formData.value.weixiu) + toFixed2(formData.value.dian) + toFixed2(formData.value.yehuaqi) + toFixed2(formData.value.yun) + toFixed2(formData.value.dianpu) + toFixed2(formData.value.qita))
        formData.value.meiyuan_hj = toFixed2(toFixed2(formData.value.qita_usd) + toFixed2(formData.value.yun_usd))
        formData.value.zjkwcjs = toFixed2(formData.value.kwc_hj) + toFixed2(formData.value.kwccyh)
        formData.value.zjmyjs = toFixed2(formData.value.meiyuan_hj) + toFixed2(formData.value.meiyuancyh)
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }
    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.value.page-1)*pageparm.value.limit + $index +1
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }

    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline.value = {
                page:1,
                limit: 10
            }
            pageparm.value={
                page: 1,
                limit: 10,
                total: 0
            }
            timers.value = []
            search()
        }else if(flag === 'add'){
            dialogTitle = "添加现金支出单"
            formData.value.jingshou = userName
            formData.value.out_time = dateFormats(new Date(),'yyyy-MM-dd hh:mm:ss')
            formData.value.dynamic_fields = []
            payOutList.value.forEach((item,index)=>{
                let data = {}
                data.id = item.id
                data.name = item.name
                data.value = null
                formData.value.dynamic_fields.push(data)
            })
            isDialogShow.value = true
        }
    }

    function callFather(parm) {
        formInline.value.page = parm.page
        formInline.value.limit = parm.limit
        getData()
    }
    function search() {
        formInline.value.page = 1
        formInline.value.limit = 10
        getData()
    }

    //获取列表
    async function getData(){
        loadingPage.value = true
        psiCashoutorder(formInline.value).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.data
                pageparm.value.page = res.data.page;
                pageparm.value.limit = res.data.limit;
                pageparm.value.total = res.data.total;
            }
        })
    }
    let payOutList = ref([])
    function psiPayOutList() {
        psiPayOut({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                payOutList.value = res.data.data
            }
        })
    }
    psiPayOutList()

    function timeChange(val){
        if (val) {
            formInline.value.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
            formInline.value.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
        } else {
            formInline.value.beginAt = null
            formInline.value.endAt = null
        }
        search()
    }
    // // 下载文件URL文件
    // function downloadFileURL(url) {
    //     var iframe =document.createElement("iframe")
    //     iframe.style.display ="none";
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    // }
    //
    // function exportData(){
    //     psiGoodsinstoreExport(formInline).then(res => {
    //          if(res.code ==2000) {
    //              downloadFileURL(res.data.data)
    //          }else{
    //               ElMessage.warning(res.msg)
    //          }
    //      })
    //
    // }
    //excel文件流下载
    function downloadExcelBlob(res) {
        let fileName = new Date().getTime() +".xlsx"
        let dispositionStr = res.headers["content-disposition"];
        if (dispositionStr == null || dispositionStr === "") {

        }else{
            // 获取文件名
            let dispositionArr = dispositionStr.split(";");
            fileName = decodeURIComponent(dispositionArr[1]);
            fileName = fileName.split("=")[1];
        }
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('导出成功')
    }
    //导出
    function exportData(row) {
        let params = {}
        if(ids.value.length>0){
            params = {
                ids:ids.value,
            }
        }
        loadingPage.value = true
        psiCashoutorderExport(params).then(res => {
            loadingPage.value = false
            downloadExcelBlob(res)
        })
    }

    let tableref = ref(null)
    //前端直接导出
    function exportDataFrontend(){
        //分页情况先显示所有数据(在第一页)
        var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
        const $table = tableref.value.$el
        const wb = XLSX.utils.table_to_book($table, xlsxParam);//table_to_book放入的是table 的DOM 节点
        var ws = wb.Sheets.Sheet1
        const wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
          });
        // 获取当前的时间戳，用来当文件名
        // let time = new Date().getTime()
        let time = "导出现金支出单数据"
        try {
            FileSaver.saveAs(
              new Blob([wbout], { type: "application/octet-stream" }),
              `${time}.xlsx`
            ); //${time}是自己导出文件时的命名，随意
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout)
          }
        return wbout
    }


    onMounted(()=>{
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
</style>