<template>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="700px" top="20px" :before-close="handleClose" :loading="dialogLoading" @closed="closed">
        <div style="display: flex;justify-content: right"><el-button type="primary" @click="handlePrint">打印</el-button></div>
        <div ref="lyOrderPrint">
            <div style="display: flex;justify-content: center;font-size: 18px;font-weight: 700;line-height: 60px;">Sales List</div>
            <div style="display: flex;justify-content: space-between;line-height: 30px;" ref="lycommonheader">
                <div>Purchasers：{{tempItem.shop_name}}</div>
                <div>Date：{{tempItem.out_time}}</div>
                <div>编号：{{tempItem.no}}</div>
            </div>
            <table class="ordertable" border="1" align="center" cellspacing="0">
                <tr>
                    <td style="width: 30px;" class="ordertableborder-right">序号</td>
                    <td style="width: 40px;" class="ordertableborder-right">品类</td>
                    <td class="ordertableborder-right">货号</td>
                    <td style="width: 50px;" class="ordertableborder-right">规格</td>
                    <td style="width: 75px;" class="ordertableborder-right">数量puantity</td>
                    <td style="width: 60px;" class="ordertableborder-right">单价price</td>
                    <td class="ordertableborder-right">金额amount</td>
<!--                    <td style="width: 60px;" class="ordertableborder-right"><div style="display: flex;flex-direction: column;"><span>数量</span><span>puantity</span></div></td>-->
<!--                    <td style="width: 60px;" class="ordertableborder-right"><div style="display: flex;flex-direction: column;"><span>单价</span><span>price</span></div></td>-->
<!--                    <td class="ordertableborder-right"><div style="display: flex;flex-direction: column;"><span>金额</span><span>amount</span></div></td>-->
                    <td class="ordertableborder-right">Cash(USD)实收美元现钞</td>
                    <td>{{tempItem.shishou_musd}}</td>
                </tr>
                <tr v-for="(itemd,indexd) in newTableData" :key="indexd">
                    <template v-if="!itemd.isempty">
                        <td class="ordertableborder-right">{{indexd+1}}</td>
                        <td class="ordertableborder-right">{{itemd.category_name}}</td>
                        <td class="ordertableborder-right">{{itemd.no}}</td>
                        <td class="ordertableborder-right">{{itemd.spec}}</td>
                        <td class="ordertableborder-right">{{itemd.nums}}</td>
                        <td class="ordertableborder-right">{{itemd.price}}</td>
                        <td class="ordertableborder-right">{{itemd.total_mount}}</td>
                        <td class="ordertableborder-right">{{itemd.gu_left_name}}</td>
                        <td>{{itemd.gu_left_value}}</td>
                    </template>
                    <template v-else>
                        <td class="ordertableborder-right">{{indexd+1}}</td>
                        <td class="ordertableborder-right"></td>
                        <td class="ordertableborder-right"></td>
                        <td class="ordertableborder-right"></td>
                        <td class="ordertableborder-right"></td>
                        <td class="ordertableborder-right"></td>
                        <td class="ordertableborder-right"></td>
                        <td class="ordertableborder-right">{{itemd.gu_left_name}}</td>
                        <td>{{itemd.gu_left_value}}</td>
                    </template>
                </tr>
                <tr>
                    <td colspan="4" class="ordertableborder-right">本次出库合计(this time)</td>
                    <td class="ordertableborder-right">{{tempItem.total_nums}}</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">{{tempItem.total_amount}}</td>
                    <td class="ordertableborder-right">备注：</td>
                    <td rowspan="5">{{tempItem.remark}}</td>
                </tr>
                <tr>
                    <td colspan="4" class="ordertableborder-right">客户上期末欠款余额(Last arrears)</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">{{tempItem.shangqimqk}}</td>
                    <td class="ordertableborder-right"></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="4" class="ordertableborder-right">总计应收款(total amount)</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">{{tempItem.total_should_amount}}</td>
                    <td class="ordertableborder-right"></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="4" class="ordertableborder-right">美元汇率</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">{{tempItem.usdrate}}</td>
                    <td class="ordertableborder-right"></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="4" class="ordertableborder-right">总计应收款折合美元</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">{{tempItem.total_yingshoumy}}</td>
                    <td class="ordertableborder-right"></td>
                    <td></td>
                </tr>

            </table>
            <div style="display: flex;justify-content: right;margin-right: 100px;line-height: 50px;">经手人：{{tempItem.fahuoer}}</div>
        </div>
    </ly-dialog>
</template>

<script setup>
    import {ref,onMounted,nextTick} from 'vue'
    import {psiGoodsoutorderGetGoodsList} from "@/api/api";
    import { ElMessage } from 'element-plus';
    import LyDialog from "@/components/dialog/dialog";
    import lyprint from '@/utils/print'
    import {deepClone} from "@/utils/util";

    const emit = defineEmits(["refreshData","closed"])

    let dialogVisible = ref(false)
    let dialogLoading = ref(false)
    let loadingSave = ref(false)
    let dialogTitle = ref("")
    let tableData = ref([])
    let newTableData = ref([])
    let tempItem = ref({})

    function handleClose() {
        dialogVisible.value = false
        loadingSave.value = false
        dialogVisible.value = false
        //emit('refreshData')
    }

    function closed() {
        emit('closed')
    }

    function moduleFn(item,flag) {
        dialogTitle.value=flag
        dialogVisible.value=true
        tempItem.value = item
        getData(item.id)
    }

    let lyOrderPrint = ref(null)
    function handlePrint() {
        lyprint(lyOrderPrint.value)
    }

    async function preData(datalist) {
        let data = datalist
        data.forEach((item,index)=>{
            data[index].isempty = false
        })
        let tdata = deepClone(data[0])
        tdata.isempty = true
        if(data.length<8){
            let cnum = 8 - data.length
            for (var i = 0; i < cnum; i++) {
                data.push(tdata)
            }
        }
        return data
    }
    
    async function preData2(data) {
        newTableData.value = []
        let newDataList = deepClone(await preData(data))
        for (var i = 0; i < newDataList.length; i++) {
            if(i == 0){
                newDataList[i].gu_left_name = "实收美元折合克瓦查(k)"
                newDataList[i].gu_left_value = tempItem.value.shishou_mzh
            }
            else if(i == 1){
                newDataList[i].gu_left_name = "Cash(K) 实收克瓦查现钞"
                newDataList[i].gu_left_value = tempItem.value.shishou_kwc
            }
            else if(i == 2){
                newDataList[i].gu_left_name = "MTN（K）实收MTN"
                newDataList[i].gu_left_value = tempItem.value.shishou_mtn
            }
            else if(i == 3){
                newDataList[i].gu_left_name = "Send to bank(K)实收存款"
                newDataList[i].gu_left_value = tempItem.value.shishou_yhck
            }
            else if(i == 4){
                newDataList[i].gu_left_name = "Total 合计实收(折合K)"
                newDataList[i].gu_left_value = tempItem.value.heji_shihouk
            }
            else if(i == 5){
                newDataList[i].gu_left_name = "其中：实收美元(USD)"
                newDataList[i].gu_left_value = tempItem.value.shishou_musd
            }
            else if(i == 6){
                newDataList[i].gu_left_name = "实收克瓦查(K)"
                newDataList[i].gu_left_value = tempItem.value.heji_shihou_kwc
            }
            else if(i == 7){
                newDataList[i].gu_left_name = "balance K本期末欠款余额"
                newDataList[i].gu_left_value = tempItem.value.shishou_mqky
            }else{
                newDataList[i].isempty = false
                newDataList[i].gu_left_name = ""
                newDataList[i].gu_left_value = ""
            }
            newTableData.value.push(newDataList[i])
        }
    }
    

    function getData(id) {
        dialogLoading.value = true
        psiGoodsoutorderGetGoodsList({id:id}).then(res=>{
            dialogLoading.value = false
            if(res.code == 2000){
                tableData.value = res.data.data
                preData2(tableData.value)
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }

    defineExpose({ moduleFn })
</script>

<style scoped>
    .ordertable{
        width: 100%;
        border-spacing: 0;
        margin: 0 auto;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-top: 1px solid #ddd;
    }
    .ordertable td{
        text-align: center;
        vertical-align: middle;
        font-size: 10px;
        border-bottom: 1px solid #ddd;
        word-break: break-all;
        padding: 0;
        height: 30px;
    }
    .ordertableborder-right{
        border-right: 1px solid #ddd;
    }
</style>