<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left">
<!--                <el-form-item label="仓库：">-->
<!--                    <el-select size="default" v-model="formInline.store" placeholder="请选择" clearable filterable style="width: 120px" @change="search">-->
<!--                        <el-option-->
<!--                                v-for="item in storeList"-->
<!--                                :key="item.id"-->
<!--                                :label="item.name"-->
<!--                                :value="item.id">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="店铺：">
                    <el-select size="default" v-model="formInline.shop" placeholder="请选择" clearable filterable style="width: 120px" @change="search">
                        <el-option
                                v-for="item in shopList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期：">
                    <el-date-picker
                        @change="search"
                        v-model="formInline.date"
                        type="date"
                        placeholder="请选择"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                      />
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="exportDataFrontend" :disabled="loadingPage">导出</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>合计金额：{{orderstatics.total_amount}}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="55"></el-table-column>-->
            <el-table-column type="index" width="60" align="center" fixed="left" label="序号"></el-table-column>
            <el-table-column
                 v-for="(item,index) in headerList"
                 min-width="115"
                :key="item.colCode"
                :label="item.colName"
                align="center"
                 :fixed="index<2?'left':false"
            >
            <template #default="scope">
                <span>{{ scope.row[item.colCode] }}</span>
            </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import {hasPermission,dateFormats,getStorage} from "@/utils/util";
    import {psiGoodsoutorderStastics,psiStore,psiShop} from '@/api/api'
    import useTableHight from '@/mixins/useTableHight';
    import { useRouter,useRoute } from 'vue-router'
    const route = useRoute()
    import * as XLSX from 'xlsx';
    import FileSaver from "file-saver"

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value,false)
    let loadingPage = ref(false)
    let loadingSave = ref(false)
    let isDialogShow = ref(false)
    let dialogTitle = ref("")
    let formData = ref({})
    let storeList = ref([])
    let shopList = ref([])
    let userName =getStorage("userName")
    let orderstatics = ref({
        total_amount:0,
    })
    let formInline = ref({
    })

    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let headerList = ref([])//列头数据
    let tableData = ref([])//数据
    let timers = ref([])
    let typeList = ref([])

    function getStoreList() {
        psiStore({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                storeList.value = res.data.data
            }
        })
    }
    function getShopList() {
        psiShop({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                shopList.value = res.data.data
            }
        })
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline.value = {
            }
            search()
        }
    }

    function search() {
        getData()
    }

    //获取列表
    async function getData(){
        loadingPage.value = true
        psiGoodsoutorderStastics(formInline.value).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.dataList
                headerList.value = res.data.headerList
                orderstatics.value.total_amount = res.data.total_amount
            }
        })
    }
    // // 下载文件URL文件
    // function downloadFileURL(url) {
    //     var iframe =document.createElement("iframe")
    //     iframe.style.display ="none";
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    // }
    //
    // function exportData(){
    //     psiGoodsinstoreExport(formInline).then(res => {
    //          if(res.code ==2000) {
    //              downloadFileURL(res.data.data)
    //          }else{
    //               ElMessage.warning(res.msg)
    //          }
    //      })
    //
    // }
    //excel文件流下载
    function downloadExcelBlob(res) {
        let fileName = new Date().getTime() +".xlsx"
        let dispositionStr = res.headers["content-disposition"];
        if (dispositionStr == null || dispositionStr === "") {

        }else{
            // 获取文件名
            let dispositionArr = dispositionStr.split(";");
            fileName = decodeURIComponent(dispositionArr[1]);
            fileName = fileName.split("=")[1];
        }
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('导出成功')
    }
    // //导出
    // function exportData(row) {
    //     let params = {}
    //     if(ids.value.length>0){
    //         params = {
    //             ids:ids.value,
    //         }
    //     }
    //     loadingPage.value = true
    //     psiStockgoodsExport(params).then(res => {
    //         loadingPage.value = false
    //         downloadExcelBlob(res)
    //     })
    // }
    let tableref = ref(null)
    //前端直接导出
    function exportDataFrontend(){
        //分页情况先显示所有数据(在第一页)
        var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
        const $table = tableref.value.$el
        const wb = XLSX.utils.table_to_book($table, xlsxParam);//table_to_book放入的是table 的DOM 节点
        const wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
          });
        // 获取当前的时间戳，用来当文件名
        // let time = new Date().getTime()
        let time = "导出出库和上货金额查询数据"
        try {
            FileSaver.saveAs(
              new Blob([wbout], { type: "application/octet-stream" }),
              `${time}.xlsx`
            ); //${time}是自己导出文件时的命名，随意
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout)
          }
        return wbout
    }

    onMounted(()=>{
        getShopList()
        // getStoreList()
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
</style>