<template>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="795px" top="20px" :before-close="handleClose" :loading="dialogLoading" @closed="closed">
        <div style="display: flex;justify-content: right"><el-button type="primary" @click="handlePrint">打印</el-button></div>
        <div ref="lyOrderPrint">
            <div style="display: flex;justify-content: center;font-size: 18px;font-weight: 700;line-height: 60px;">INVOICE</div>
            <div style="display: flex;justify-content: space-between;line-height: 30px;" ref="lycommonheader">
                <div>SHOP：{{tempItem.shop_name}}</div>
                <div>Date：{{tempItem.in_time}}</div>
                <div>serial number：{{tempItem.no}}</div>
            </div>
            <table class="ordertable" border="1" align="center" cellspacing="0">
                <tr>
                    <td style="width: 30px;" class="ordertableborder-right">序号</td>
                    <td class="ordertableborder-right">Article number</td>
                    <td class="ordertableborder-right">Quantity</td>
                    <td class="ordertableborder-right">Price</td>
                    <td class="ordertableborder-right">Amount</td>
                    <td style="width: 100px;" class="ordertableborder-right">备注</td>
                </tr>
                <tr v-for="(itemd,indexd) in tableData" :key="indexd">
                    <td class="ordertableborder-right">{{indexd+1}}</td>
                    <td class="ordertableborder-right">{{itemd.no}}</td>
                    <td class="ordertableborder-right">{{itemd.nums}}</td>
                    <td class="ordertableborder-right">{{itemd.price}}</td>
                    <td class="ordertableborder-right">{{itemd.total_mount}}</td>
                    <td class="ordertableborder-right">{{itemd.remark}}</td>
                </tr>
                <tr>
                    <td class="ordertableborder-right">合计</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">{{tempItem.total_nums}}</td>
                    <td class="ordertableborder-right">— —</td>
                    <td class="ordertableborder-right">{{tempItem.total_amount}}</td>
                    <td class="ordertableborder-right">— —</td>
                </tr>

            </table>
            <div style="display: flex;justify-content: space-between;line-height: 50px;">
                <div>Shop Signature：{{tempItem.tihuoer}}</div>
                <div style="margin-right: 100px;">发货人签字:</div>
            </div>
        </div>
    </ly-dialog>
</template>

<script setup>
    import {ref,onMounted,nextTick} from 'vue'
    import {psiShopingoodsorderGoodsList} from "@/api/api";
    import { ElMessage } from 'element-plus';
    import LyDialog from "@/components/dialog/dialog";
    import lyprint from '@/utils/print'
    import {deepClone} from "@/utils/util";

    const emit = defineEmits(["refreshData","closed"])

    let dialogVisible = ref(false)
    let dialogLoading = ref(false)
    let loadingSave = ref(false)
    let dialogTitle = ref("")
    let tableData = ref([])
    let tempItem = ref({})

    function handleClose() {
        dialogVisible.value = false
        loadingSave.value = false
        dialogVisible.value = false
        //emit('refreshData')
    }

    function closed() {
        emit('closed')
    }

    function moduleFn(item,flag) {
        dialogTitle.value=flag
        dialogVisible.value=true
        tempItem.value = item
        getData(item.id)
    }

    let lyOrderPrint = ref(null)
    function handlePrint() {
        lyprint(lyOrderPrint.value)
    }

    function getData(id) {
        dialogLoading.value = true
        psiShopingoodsorderGoodsList({id:id}).then(res=>{
            dialogLoading.value = false
            if(res.code == 2000){
                tableData.value = res.data.data
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }

    defineExpose({ moduleFn })
</script>

<style scoped>
    .ordertable{
        width: 100%;
        border-spacing: 0;
        margin: 0 auto;
        border-left: 1px solid #ddd;
        /*border-right: 1px solid #ddd;*/
        border-top: 1px solid #ddd;
    }
    .ordertable td{
        text-align: center;
        vertical-align: middle;
        font-size: 10px;
        border-bottom: 1px solid #ddd;
        word-break: break-all;
        padding: 0;
        height: 30px;
    }
    .ordertableborder-right{
        border-right: 1px solid #ddd;
    }
</style>