<template>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="796px" top="20px" :before-close="handleClose" :loading="dialogLoading" @closed="closed">
        <div style="display: flex;justify-content: right"><el-button type="primary" @click="handlePrint">打印</el-button></div>
        <div id="pageHeight" ref="lyOrderPrint">
            <div style="display: flex;justify-content: center;font-size: 18px;line-height: 60px;" id="printTitle">店铺盘货情况查询</div>
            <div style="display: flex;justify-content: left;font-size: 15px;line-height: 30px;" ref="lycommonheader" id="msgTitle">
                <div>店铺：{{shopname}}</div>
                <div style="margin-left: 30px;">日期：{{tempItem.beginAt +"--" + tempItem.endAt}}</div>
            </div>
            <table class="ordertable" id="bottomDiv" border="1" align="center" cellspacing="0">
                <tr id="bottomTh">
                    <td style="width: 30px;" class="ordertableborder-right">序号</td>
                    <td class="ordertableborder-right">货号</td>
                    <td class="ordertableborder-right">出库单价</td>
                    <td class="ordertableborder-right">上期盘存数量</td>
                    <td class="ordertableborder-right">本期上货数量</td>
                    <td class="ordertableborder-right">本期退库数量</td>
                    <td class="ordertableborder-right">本期应盘存数量</td>
                    <td class="ordertableborder-right">本期实际盘存数量</td>
                    <td class="ordertableborder-right">本期销售数量</td>
                    <td class="ordertableborder-right">本期销售金额</td>
                </tr>
                <tr v-for="(itemd,indexd) in tableData" :key="indexd"  :id="`bottomTd_${itemd.id}`">
                    <td class="ordertableborder-right">{{indexd+1}}</td>
                    <td class="ordertableborder-right">{{itemd.no}}</td>
                    <td class="ordertableborder-right">{{itemd.price}}</td>
                    <td class="ordertableborder-right">{{itemd.sq_pcsl}}</td>
                    <td class="ordertableborder-right">{{itemd.bq_shsl}}</td>
                    <td class="ordertableborder-right">{{itemd.bq_thsl}}</td>
                    <td class="ordertableborder-right">{{itemd.bq_ypcsl}}</td>
                    <td class="ordertableborder-right"></td>
                    <td class="ordertableborder-right"></td>
                    <td class="ordertableborder-right"></td>
                </tr>

            </table>
        </div>
    </ly-dialog>
</template>

<script setup>
    import {ref,reactive,onMounted,nextTick} from 'vue'
    import {psiShoppankuorderStastics} from "@/api/api";
    import { ElMessage } from 'element-plus';
    import LyDialog from "@/components/dialog/dialog";
    import print from '@/utils/print'
    import {deepClone} from "@/utils/util";

    const emit = defineEmits(["refreshData","closed"])

    let dialogVisible = ref(false)
    let dialogLoading = ref(false)
    let loadingSave = ref(false)
    let dialogTitle = ref("")
    let tableData = ref([])
    let shopname = ref("")
    let tempItem = ref({})
    //分页打印关键参数
    let paperHeight = ref(undefined)//1123
    let printData = ref([])//处理后要分页的data数据
    let totalPage = ref(1)//总页数


    function handleClose() {
        dialogVisible.value = false
        loadingSave.value = false
        dialogVisible.value = false
        //emit('refreshData')
    }

    function closed() {
        emit('closed')
    }

    function moduleFn(item,flag) {
        dialogTitle.value=flag
        dialogVisible.value=true
        tempItem.value = item
        getData(item)
    }

    let lyOrderPrint = ref(null)
    function handlePrint() {
        print(lyOrderPrint.value)
    }

    function getData(formInline) {
        dialogLoading.value = true
        psiShoppankuorderStastics(formInline).then(res=>{
            dialogLoading.value = false
            if(res.code == 2000){
                tableData.value = res.data.dataList
                //模拟数据
                // for(var i = 0; i <= 60; i++){
                //     tableData.value.push(
                //         res.data.dataList[0]
                //     )
                // }
                shopname.value = res.data.shopname
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }

    defineExpose({ moduleFn })

</script>

<style scoped>
    /*@page {size: auto;margin: 0mm 8mm;}*/
    .ordertable{
        width: 100%;
        border-spacing: 0;
        margin: 0 auto;
        border-left: 1px solid #ddd;
        /*border-right: 1px solid #ddd;*/
        border-top: 1px solid #ddd;
        font-size:10px;
        text-align: center;
    }
    .ordertable td{
        text-align: center;
        vertical-align: middle;
        font-size: 10px;
        border-bottom: 1px solid #ddd;
        word-break: break-all;
        padding: 0;
        height: 30px;
    }
    .ordertableborder-right{
        border-right: 1px solid #ddd;
    }
    .page_height {
        width: 200mm;
        height: 297mm;
        box-sizing: border-box;
        margin-bottom: 85px;
        position: relative;
    }
    .use-get-height {
        position: absolute;
        top: 0;
        z-index: -1;
        opacity: 0;
    }
    .print_div {
        page-break-before: always;
        page-break-after: avoid;
        page-break-inside: avoid;
        margin: 0 auto;
    }
    .page_div {
        height: 85%;
        overflow: auto;
        display: flex;
    }
    .page-message {
        text-align: center;
        page-break-before: avoid;
        page-break-after: always;
        page-break-inside: avoid;
        position: absolute;
        bottom: 0;
        /*left: 49%;*/
        right:0%;
    }
</style>