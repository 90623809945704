<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left">
                <el-form-item label="出库单编号：">
                    <el-input v-model.trim="formInline.no" maxlength="60"  clearable placeholder="出库单编号" @change="search" style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="客户(店铺)：">
                    <el-select size="default" v-model="formInline.shop" placeholder="请选择" clearable style="width: 120px" @change="search">
                        <el-option
                                v-for="item in shopList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="品类：">
                    <el-select size="default" v-model="formInline.category" placeholder="请选择" clearable style="width: 100px" @change="search">
                        <el-option
                                v-for="item in categoryList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="货号：">
                    <el-input v-model.trim="formInline.goodsno" maxlength="60"  clearable placeholder="货号" @change="search" style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="出库日期：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Export')"><el-button type="primary" @click="exportData" :disabled="loadingPage">导出</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="120" prop="no" label="出库单编号"></el-table-column>
            <el-table-column min-width="100" prop="shop" label="客户(店铺)"></el-table-column>
            <el-table-column min-width="100" prop="category_name" label="品类"></el-table-column>
            <el-table-column min-width="150" prop="goodsno" label="货号"></el-table-column>
            <el-table-column min-width="70" prop="spec" label="规格"></el-table-column>
            <el-table-column min-width="80" prop="nums" label="出库数量"></el-table-column>
            <el-table-column min-width="100" prop="price" label="出库单价"></el-table-column>
            <el-table-column min-width="100" prop="total_mount" label="出库金额"></el-table-column>
            <el-table-column min-width="180" prop="out_time" label="出库日期"></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import Pagination from "@/components/Pagination";
    import {hasPermission, dateFormats, getStorage, lyParseParam2UrlParam} from "@/utils/util";
    import {psiOutstoregoods,psiStore,psiShop,psiGoods,psiCategory,psiOutstoregoodsExport} from '@/api/api'
    import useTableHight from '@/mixins/useTableHight';
    import { useRouter,useRoute } from 'vue-router'
    const route = useRoute()

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value)
    let loadingPage = ref(false)
    let loadingSave = ref(false)
    let isDialogShow = ref(false)
    let dialogTitle = ref("")
    let formData = ref({})
    let storeList = ref([])
    let shopList = ref([])
    let categoryList = ref([])
    let userName =getStorage("userName")
    let orderstatics = ref({
        total_nums:0,
    })
    let formInline = ref({
        page: 1,
        limit: 10,
    })

    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let pageparm = ref({
        page: 1,
        limit: 10,
        total: 0
    })
    let tableData = ref([])
    let timers = ref([])
    let typeList = ref([])
    let statusList = ref([
        {id:3,name:'已完成'},
        {id:1,name:'待审核'},
        {id:2,name:'已拒绝'},
    ])

    function getStoreList() {
        psiStore({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                storeList.value = res.data.data
            }
        })
    }
    function getpsiShopList() {
        psiShop({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                shopList.value = res.data.data
            }
        })
    }

    function getCategoryList() {
        psiCategory({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                categoryList.value = res.data.data
            }
        })
    }

    const getSummaries = (param) => {
        const { columns, data } = param
        const sums= []
        columns.forEach((column, index) => {
            // 设置第一列想显示的字
            if (index === 0) {
                sums[index] = '合计'
                return
            }

            const values = data.map((item) => Number(item[column.property]))

            if (index != 5 &&  index != 7) {  // 想自定义求和的列
                // Number.isNaN(value)) 判断value是否是数值类
                sums[index] = '-'
            } else {
                let sum = 0;
                values.forEach(item => {
                    sum = sum + item;
                })
                sums[index] = sum;
            }
        })
        return sums
    }

    function getGoodsApi() {
        return psiGoods
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }
    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.value.page-1)*pageparm.value.limit + $index +1
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline.value = {
                page:1,
                limit: 10
            }
            pageparm.value={
                page: 1,
                limit: 10,
                total: 0
            }
            timers.value = []
            search()
        }
    }

    function callFather(parm) {
        formInline.value.page = parm.page
        formInline.value.limit = parm.limit
        getData()
    }
    function search() {
        formInline.value.page = 1
        formInline.value.limit = 10
        getData()
    }

    //获取列表
    async function getData(){
        loadingPage.value = true
        psiOutstoregoods(formInline.value).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                console.log(res.data.data.page)
                tableData.value = res.data.data
                pageparm.value.page = res.data.page;
                pageparm.value.limit = res.data.limit;
                pageparm.value.total = res.data.total;
            }
        })
    }
    function timeChange(val){
        if (val) {
            formInline.value.beginAt=dateFormats(val[0],'yyyy-MM-dd  hh:mm:ss');
            formInline.value.endAt=dateFormats(val[1],'yyyy-MM-dd  hh:mm:ss');
        } else {
            formInline.value.beginAt = null
            formInline.value.endAt = null
        }
        search()
    }
    // // 下载文件URL文件
    // function downloadFileURL(url) {
    //     var iframe =document.createElement("iframe")
    //     iframe.style.display ="none";
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    // }
    //
    // function exportData(){
    //     psiGoodsinstoreExport(formInline).then(res => {
    //          if(res.code ==2000) {
    //              downloadFileURL(res.data.data)
    //          }else{
    //               ElMessage.warning(res.msg)
    //          }
    //      })
    //
    // }
    //excel文件流下载
    function downloadExcelBlob(res) {
        let fileName = new Date().getTime() +".xlsx"
        let dispositionStr = res.headers["content-disposition"];
        if (dispositionStr == null || dispositionStr === "") {

        }else{
            // 获取文件名
            let dispositionArr = dispositionStr.split(";");
            fileName = decodeURIComponent(dispositionArr[1]);
            fileName = fileName.split("=")[1];
        }
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('导出成功')
    }
    //导出
    function exportData(row) {
        let params = {}
        let urlparams = lyParseParam2UrlParam(formInline.value)
        urlparams = !!urlparams?"?"+urlparams:''
        if(ids.value.length>0){
            params['ids'] = ids.value
        }
        loadingPage.value = true
        psiOutstoregoodsExport(urlparams,params).then(res => {
            loadingPage.value = false
            downloadExcelBlob(res)
        })
    }

    onMounted(()=>{
        getCategoryList()
        getpsiShopList()
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
</style>