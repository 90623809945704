<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left">
                <el-form-item label="货号：">
                    <el-input v-model.trim="formInline.no" maxlength="60"  clearable placeholder="货号" @change="search" style="width:160px"></el-input>
                </el-form-item>
                <el-form-item label="店铺" prop="shop" v-if="!(roleKey.includes('shop') && roleKey.length<2)">
                    <el-select v-model="formInline.shop" placeholder="请选择" clearable style="width: 180px" @change="search">
                        <el-option
                            v-for="item in shopList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="退货日期：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="handleEdit('','add')">新增</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="exportData" :disabled="loadingPage">导出</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="180" prop="tui_time" label="退货日期"></el-table-column>
            <el-table-column min-width="120" prop="shop_name" label="店铺" show-overflow-tooltip></el-table-column>
             <el-table-column min-width="120" prop="goodsinfo.no" label="货号"></el-table-column>
            <el-table-column min-width="100" prop="store_name" label="退库仓库"></el-table-column>
            <el-table-column min-width="100" prop="nums" label="退库数量"></el-table-column>
            <el-table-column min-width="100" prop="price" label="出库单价"></el-table-column>
            <el-table-column min-width="100" prop="total_mount" label="退库金额"></el-table-column>
            <el-table-column min-width="150" prop="tuihuoer" label="退库人"></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <ly-dialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="70%" top="10px" :before-close="handleDialogClose" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <div class="ly-title">
                    基础信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="店铺" prop="shop">
                            <el-select v-model="formData.shop" placeholder="请选择" :disabled="shopDisable" filterable style="width: 100%">
                                <el-option
                                    v-for="item in shopList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="退货日期" prop="tui_time">
                            <el-date-picker
                                style="width: 100%"
                                v-model="formData.tui_time"
                                type="datetime"
                                placeholder="选择时间"
                                format="YYYY-MM-DD HH:mm:ss"
                                value-format="YYYY-MM-DD HH:mm:ss"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="收货人" prop="shouhuoer">
                            <el-input v-model="formData.shouhuoer" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="退货人" prop="tuihuoer">
                            <el-input v-model="formData.tuihuoer" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    明细
                </div>
                <el-form-item prop="goodslist">
                    <el-table :data="formData.goodslist" ref="lyaddtable" border stripe show-summary :summary-method="getSummaries">
                        <el-table-column type="index" width="60" fixed="left">
                            <template #header>
                                <ly-table-select button-type="primary" button-icon="plus" size="small" :button-circle="true" ref="lymanualSelect" :apiObj="getGoodsApi" @click="handleManualSelectClick" :table-width="1000" multiple @confirm="handleManualConfirmClick">
                                    <template #header="{form, submit}">
                                        <el-form :inline="true" :model="form">
                                            <el-form-item>
                                                <el-date-picker
                                                    size="small"
                                                    style="width:320px"
                                                    v-model="form.timerange"
                                                    type="datetimerange"
                                                    range-separator="至"
                                                    start-placeholder="创建开始日期"
                                                    end-placeholder="创建结束日期">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-input type="text" v-model="form.no" placeholder="货号" size="small" style="width: 120px;" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-select  v-model="form.category" placeholder="品类" clearable style="width: 120px;" size="small">
                                                    <el-option
                                                        v-for="item in categoryList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submit" size="small">查询</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </template>
                                    <el-table-column prop="goodsinfo.category_name" label="品类" show-overflow-tooltip min-width="100"></el-table-column>
                                    <el-table-column prop="goodsinfo.no" label="货号" show-overflow-tooltip min-width="200"></el-table-column>
                                    <el-table-column prop="goodsinfo.spec" label="规格" show-overflow-tooltip min-width="100"></el-table-column>
                                    <el-table-column prop="goodsinfo.image" label="商品图"  min-width="130">
                                        <template #default="scope">
                                            <el-image  :src=scope.row.goodsinfo.image :preview-src-list="[scope.row.goodsinfo.image]" :preview-teleported="true" style="width: 35px;height: 35px"></el-image>
                                        </template>
                                    </el-table-column>
                                </ly-table-select>
                            </template>
                            <template #default="scope">
                                <div class="ly-form-table-handle">
                                    <span>{{scope.$index + 1}}</span>
                                    <el-button type="danger" icon="delete" size="small" plain circle @click="rowDel(scope.row, scope.$index)"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsinfo.goodsinfo.category_name" label="品类" min-width="120" :resizable="false"></el-table-column>
                        <el-table-column prop="goodsinfo.goodsinfo.no" label="货号" min-width="180" show-overflow-tooltip :resizable="false"></el-table-column>
<!--                        <el-table-column prop="goodsinfo.goodsinfo.image" label="商品图" min-width="130">-->
<!--                            <template #default="scope">-->
<!--                                <el-image  :src=scope.row.goodsinfo.goodsinfo.image :preview-src-list="[scope.row.goodsinfo.goodsinfo.image]" :preview-teleported="true" style="width: 40px;height: 40px"></el-image>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column prop="goodsinfo.goodsinfo.spec" label="规格" min-width="100" :resizable="false"></el-table-column>-->
                        <el-table-column prop="nums" label="退库仓库" width="180" :resizable="false">
                             <template #header>
                                <span style="color: red">*</span>退库仓库
                            </template>
                            <template #default="scope">
                                <el-select  v-model="scope.row.goodsinfo.store" placeholder="仓库" clearable style="width: 100%;">
                                    <el-option
                                        v-for="item in scope.row.goodsinfo.store_list"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nums" label="退库数量" width="180" :resizable="false">
                             <template #header>
                                <span style="color: red">*</span>退库数量
                            </template>
                            <template #default="scope">
                                <el-input-number :precision="0" :controls="false" v-model="scope.row.nums" placeholder="手动输入" @change="handleChangeCalc(scope.$index)"></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsinfo.goodsinfo.price" label="出库单价(k)" width="150" :resizable="false"></el-table-column>
                        <el-table-column prop="total_mount" label="退库金额（k）" width="180">
                            <template #default="scope">
                                <el-input v-model="scope.row.total_mount" placeholder="自动计算" :disabled="true" :resizable="false"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" label="备注" min-width="180">
                            <template #default="scope">
                                <el-input v-model="scope.row.remark" placeholder="输入"></el-input>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
        <moudleShopInGoodsList v-if="showGoodsList" ref="modulegoodslistFlag" @close = "showGoodsList = false"></moudleShopInGoodsList>
        <moduleShopInGoodsOrderPrint v-if="showPrint" ref="modulePrintFlag" @close = "showPrint = false"></moduleShopInGoodsOrderPrint>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import Pagination from "@/components/Pagination";
    import {hasPermission, dateFormats, getStorage, lyParseParam2UrlParam} from "@/utils/util";
    import {psiShopreturngoods,psiShop,psiStore,psiShopstockgoods,psiCategory,psiShopreturngoodsAdd,psiShopreturngoodsExport} from '@/api/api'
    import useTableHight from '@/mixins/useTableHight';
    import { useRouter,useRoute } from 'vue-router'
    import LyDialog from "@/components/dialog/dialog";
    import LyTableSelect from "@/components/lyTableSelect";
    import moudleShopInGoodsList from "./components/moudleShopInGoodsList"
    import moduleShopInGoodsOrderPrint from "./components/moduleShopInGoodsOrderPrint"
    const route = useRoute()

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value)
    let loadingPage = ref(false)
    let loadingSave = ref(false)
    let isDialogShow = ref(false)
    let dialogTitle = ref("")
    let formData = ref({})
    let storeList = ref([])
    let categoryList = ref([])
    let shopList = ref([])
    let userName =getStorage("userName")
    let roleKey =getStorage("roleKey") || []
    let shopDisable = (getStorage("identity") == 0 || getStorage("identity") == 2)?false:true
    let rules = reactive({
        shop: [
            {required: true, message: '请选择店铺',trigger: 'blur'}
        ],
        tui_time: [
            {required: true, message: '请选择退货日期',trigger: 'blur'}
        ],
        tuihuoer: [
            {required: true, message: '请输入退货人',trigger: 'blur'}
        ]
    })

    function handleDialogClose(){
        isDialogShow.value = false
        formData.value = {}
    }
    let formInline = ref({
        page: 1,
        limit: 10,
    })

    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let pageparm = ref({
        page: 1,
        limit: 10,
        total: 0
    })
    let tableData = ref([])
    let timers = ref([])
    let typeList = ref([])
    let statusList = ref([
        {id:3,name:'已完成'},
        {id:1,name:'待审核'},
        {id:2,name:'已拒绝'},
    ])

    function getStoreList() {
        psiStore({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                storeList.value = res.data.data
            }
        })
    }
    function getShopList() {
        psiShop({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                shopList.value = res.data.data
            }
        })
    }
    function getCategoryList() {
        psiCategory({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                categoryList.value = res.data.data
            }
        })
    }

    let lymanualSelect = ref(null)
    function handleManualSelectClick(){
        // if(formData.shop == "" || formData.shop==undefined){
        //     ElMessage.warning("请选择客户")
        //     return
        // }
        lymanualSelect.value.handleChooseClick()
    }
    function handleManualConfirmClick(items){
        //去重赋值
        if(items.length>0){
            items.forEach(item=>{
                //如果不存在返回值为-1(去重)
                if(formData.value.goodslist.findIndex(item1=>item1.goodsinfo.id === item.id ) == -1){
                    formData.value.goodslist.push({
                        goodsinfo:item,
                        nums:null,
                        total_mount:null,
                        remark:"",
                    })
                    console.log(formData.value)
                }
            })
        }
    }
    function getGoodsApi() {
        return psiShopstockgoods
    }
    //保留小数后两位（不四舍五入）
    function toFixed2(value) {
        if(!!value){
            // 截取当前数据到小数点后三位(保留后两位小数不四舍五入)
            let tempVal = parseFloat(value).toFixed(3)
            let realVal = tempVal.substring(0, tempVal.length - 1)
            return Number(realVal)
        }else{
            return 0
        }

    }
    function handleChangeCalc(index){
        formData.value.goodslist[index].total_mount =toFixed2(formData.value.goodslist[index].goodsinfo.goodsinfo.price) * formData.value.goodslist[index].nums
    }
    //提交保存数据
    let rulesForm = ref(null)
    function submitData(){
        rulesForm.value.validate(obj=>{
            if(obj) {
                loadingSave.value=true
                let param = {
                    ...formData.value
                }
                var goodslist = param.goodslist
                var ispass = true
                for(var i =0;i<goodslist.length;i++){
                    if(goodslist[i].nums ==null ||goodslist[i].goodsinfo.store == null ||goodslist[i].goodsinfo.store == "" ||goodslist[i].goodsinfo.store == undefined){
                        ispass = false
                        break
                    }
                }
                if(!ispass){
                    ElMessage.warning("必填内容不能为空，请填写后再提交")
                    loadingSave.value=false
                    return
                }
                psiShopreturngoodsAdd(param).then(res=>{
                    loadingSave.value=false
                    if(res.code ==2000) {
                        ElMessage.success(res.msg)
                        handleDialogClose()
                        getData()
                    } else {
                       ElMessage.warning(res.msg)
                    }
                })

            }
        })
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }
    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.value.page-1)*pageparm.value.limit + $index +1
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
     /** 添加表格操作 */
    function rowDel(row, index){
         formData.value.goodslist.splice(index, 1)
    }
    let showGoodsList = ref(false)
    let modulegoodslistFlag =ref(null)
    let showPrint = ref(false)
    let modulePrintFlag =ref(null)
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline.value = {
                page:1,
                limit: 10
            }
            pageparm.value={
                page: 1,
                limit: 10,
                total: 0
            }
            timers.value = []
            search()
        } else if(flag === 'add'){
            // if(roleKey.includes('shop')){
                getCategoryList()
                // getStoreList()
                dialogTitle = "添加店铺退货单"
                formData.value.shouhuoer = userName
                formData.value.tui_time = dateFormats(new Date(),'yyyy-MM-dd hh:mm:ss')
                formData.value.goodslist = []
                isDialogShow.value = true
                if(shopDisable){
                    formData.value.shop = getStorage("deptId")
                }
            // }else{
            //     ElMessage.warning("非店铺管理员无法增加")
            // }
        }
    }
    const getSummaries = (param) => {
        const { columns, data } = param
        const sums= []
        columns.forEach((column, index) => {
            // 设置第一列想显示的字
            if (index === 0) {
                sums[index] = '合计'
                return
            }

            const values = data.map((item) => Number(item[column.property]))

            if (index != 4 &&  index != 6) {  // 想自定义求和的列
                // Number.isNaN(value)) 判断value是否是数值类
                sums[index] = '-'
            } else {
                let sum = 0;
                values.forEach(item => {
                    sum = sum + item;
                })
                sums[index] = sum;
                if(index  == 4){
                    formData.value.nums = sum
                }else if(index == 6){
                    formData.value.total_mount = sum
                }
            }
        })
        return sums
    }
    function callFather(parm) {
        formInline.value.page = parm.page
        formInline.value.limit = parm.limit
        getData()
    }
    function search() {
        formInline.value.page = 1
        formInline.value.limit = 10
        getData()
    }

    //获取列表
    async function getData(){
        loadingPage.value = true
        psiShopreturngoods(formInline.value).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.data
                pageparm.value.page = res.data.page;
                pageparm.value.limit = res.data.limit;
                pageparm.value.total = res.data.total;
            }
        })
    }
    function timeChange(val){
        if (val) {
            formInline.value.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
            formInline.value.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
        } else {
            formInline.value.beginAt = null
            formInline.value.endAt = null
        }
        search()
    }
    // // 下载文件URL文件
    // function downloadFileURL(url) {
    //     var iframe =document.createElement("iframe")
    //     iframe.style.display ="none";
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    // }
    //
    // function exportData(){
    //     psiGoodsinstoreExport(formInline).then(res => {
    //          if(res.code ==2000) {
    //              downloadFileURL(res.data.data)
    //          }else{
    //               ElMessage.warning(res.msg)
    //          }
    //      })
    //
    // }
    //excel文件流下载
    function downloadExcelBlob(res) {
        let fileName = new Date().getTime() +".xlsx"
        let dispositionStr = res.headers["content-disposition"];
        if (dispositionStr == null || dispositionStr === "") {

        }else{
            // 获取文件名
            let dispositionArr = dispositionStr.split(";");
            fileName = decodeURIComponent(dispositionArr[1]);
            fileName = fileName.split("=")[1];
        }
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('导出成功')
    }
    //导出
    function exportData(row) {
        let params = {}
        let urlparams = lyParseParam2UrlParam(formInline.value)
        urlparams = !!urlparams?"?"+urlparams:''
        if(ids.value.length>0){
            params['ids'] = ids.value
        }
        loadingPage.value = true
        psiShopreturngoodsExport(urlparams,params).then(res => {
            loadingPage.value = false
            downloadExcelBlob(res)
        })
    }

    onMounted(()=>{
        getShopList()
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
    .lyTableCustomAppend{
        display: flex;
        align-items: center;
        border-bottom:var(--el-table-border);
    }
    .lytable-borderright{
        border-right: var(--el-table-border);
    }
    .lytable-borderleft{
        border-left: var(--el-table-border);
    }
</style>