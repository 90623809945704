<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left">
                <el-form-item label="出库单编号：">
                    <el-input v-model.trim="formInline.no" maxlength="60"  clearable placeholder="出库单编号" @change="search" style="width:160px"></el-input>
                </el-form-item>
                <el-form-item label="店铺" prop="shop" v-if="!(roleKey.includes('shop') && roleKey.length<2)">
                    <el-select v-model="formInline.shop" placeholder="请选择" clearable style="width: 180px" @change="search">
                        <el-option
                            v-for="item in shopList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出库日期：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="handleEdit('','add')">新增</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="exportData" :disabled="loadingPage">导出</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="180" prop="no" label="出库单编号" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="180" prop="out_time" label="出库日期"></el-table-column>
            <el-table-column min-width="120" prop="shop_name" label="客户" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="100" prop="total_nums" label="出库数量"></el-table-column>
            <el-table-column min-width="100" prop="total_amount" label="出库金额"></el-table-column>
            <el-table-column min-width="150" prop="shishou_musd" label="实收美元现钞USD"></el-table-column>
            <el-table-column min-width="150" prop="shishou_mzh" label="实收美元现钞折合K"></el-table-column>
            <el-table-column min-width="150" prop="shishou_kwc" label="实收克瓦查现钞K"></el-table-column>
            <el-table-column min-width="150" prop="shishou_mtn" label="实收MTN(K)"></el-table-column>
            <el-table-column min-width="150" prop="shishou_yhck" label="实收银行存款K"></el-table-column>
            <el-table-column min-width="150" prop="shishou_bqk" label="本期新增欠款（K）"></el-table-column>
            <el-table-column min-width="150" prop="shishou_mqky" label="本期末欠款余额K"></el-table-column>
            <el-table-column min-width="100" prop="fahuoer" label="发货人"></el-table-column>
            <el-table-column min-width="130" prop="remark" label="备注" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'goodslist')" v-show="hasPermission(route.name,'Detailed')">明细</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'print')">打印</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <ly-dialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="70%" top="10px" :before-close="handleDialogClose" :fullscreen="true" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <div class="ly-title">
                    基础信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="客户" prop="shop">
                            <el-select v-model="formData.shop" placeholder="请选择" filterable @change="handleShopSelectChange" style="width: 100%">
                                <el-option
                                    v-for="item in shopList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出库日期" prop="out_time">
                            <el-date-picker
                                style="width: 100%"
                                v-model="formData.out_time"
                                type="datetime"
                                placeholder="选择时间"
                                format="YYYY-MM-DD HH:mm:ss"
                                value-format="YYYY-MM-DD HH:mm:ss"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="发货人" prop="fahuoer">
                            <el-input v-model="formData.fahuoer" placeholder="请输入" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    明细
                </div>
                <el-form-item prop="goodslist">
                    <el-table :data="formData.goodslist" ref="lyaddtable" border stripe row-key="id">
                        <el-table-column type="index" width="60" fixed="left">
                            <template #header>
                                <ly-table-select button-type="primary" button-icon="plus" size="small" :button-circle="true" ref="lymanualSelect" :apiObj="getGoodsApi" @click="handleManualSelectClick" :table-width="1000" multiple @confirm="handleManualConfirmClick">
                                    <template #header="{form, submit}">
                                        <el-form :inline="true" :model="form">
                                            <el-form-item>
                                                <el-date-picker
                                                    size="small"
                                                    style="width:320px"
                                                    v-model="form.timerange"
                                                    type="datetimerange"
                                                    range-separator="至"
                                                    start-placeholder="创建开始日期"
                                                    end-placeholder="创建结束日期">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-input type="text" v-model="form.no" placeholder="货号" size="small" style="width: 120px;" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-select  v-model="form.category" placeholder="品类" clearable style="width: 120px;" size="small">
                                                    <el-option
                                                        v-for="item in categoryList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-select  v-model="form.store" placeholder="仓库" clearable style="width: 120px;" size="small">
                                                    <el-option
                                                        v-for="item in storeList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submit" size="small">查询</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </template>
                                    <el-table-column prop="goodsinfo.category_name" label="品类" show-overflow-tooltip min-width="100"></el-table-column>
                                    <el-table-column prop="goodsinfo.no" label="货号" show-overflow-tooltip min-width="200"></el-table-column>
                                    <el-table-column prop="goodsinfo.spec" label="规格" show-overflow-tooltip min-width="100"></el-table-column>
                                    <el-table-column prop="goodsinfo.image" label="商品图"  min-width="130">
                                        <template #default="scope">
                                            <el-image  :src=scope.row.goodsinfo.image :preview-src-list="[scope.row.goodsinfo.image]" :preview-teleported="true" style="width: 35px;height: 35px"></el-image>
                                        </template>
                                    </el-table-column>
                                </ly-table-select>
                            </template>
                            <template #default="scope">
                                <div class="ly-form-table-handle">
                                    <span>{{scope.$index + 1}}</span>
                                    <el-button type="danger" icon="delete" size="small" plain circle @click="rowDel(scope.row, scope.$index)"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsinfo.goodsinfo.category_name" label="品类" min-width="120" :resizable="false"></el-table-column>
                        <el-table-column prop="goodsinfo.goodsinfo.no" label="货号" min-width="180" show-overflow-tooltip :resizable="false"></el-table-column>
<!--                        <el-table-column prop="goodsinfo.goodsinfo.image" label="商品图" min-width="130">-->
<!--                            <template #default="scope">-->
<!--                                <el-image  :src=scope.row.goodsinfo.goodsinfo.image :preview-src-list="[scope.row.goodsinfo.goodsinfo.image]" :preview-teleported="true" style="width: 40px;height: 40px"></el-image>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                        <el-table-column prop="goodsinfo.goodsinfo.spec" label="规格" min-width="100" :resizable="false"></el-table-column>
                        <el-table-column prop="nums" label="出库数量" width="180" :resizable="false">
                             <template #header>
                                <span style="color: red">*</span>出库数量(默认全部)
                            </template>
                            <template #default="scope">
                                <el-input-number @change="handleNumsChangeCalc(scope.row,scope.$index)" :precision="0" :controls="false" v-model="scope.row.nums" :max="scope.row.goodsinfo.stock_nums" placeholder="手动输入"></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsinfo.goodsinfo.price" label="出库单价(k)" width="150" :resizable="false"></el-table-column>
                        <el-table-column prop="total_mount" label="出库金额(k)" width="180">
                            <template #default="scope">
                                <el-input v-model="scope.row.total_mount" placeholder="自动计算" :disabled="true" :resizable="false"></el-input>
                            </template>
                        </el-table-column>
                        <template #append>
                            <div class="lyTableCustomAppend">
                                <div style="flex: 1;text-align: center;">本次出库合计</div>
                                <div class="lytable-borderright lytable-borderleft" style="width: 179px;"><span style="margin-left: 12px">{{formData.total_nums||0}}</span></div>
                                <div class="lytable-borderright" style="width: 149px;"><span style="margin-left: 12px">— —</span></div>
                                <div style="width: 180px;"><span style="margin-left: 12px">{{formData.total_amount||0}}</span></div>
                            </div>
                            <div class="lyTableCustomAppend">
                                <div style="flex: 1;text-align: center;">客户上期末欠款余额</div>
                                <div class="lytable-borderright lytable-borderleft" style="width: 179px;"><span style="margin-left: 12px">— —</span></div>
                                <div class="lytable-borderright" style="width: 149px;"><span style="margin-left: 12px">— —</span></div>
                                <div style="width: 180px;"><span style="margin-left: 12px">{{formData.shangqimqk||0}}</span></div>
                            </div>
                            <div class="lyTableCustomAppend">
                                <div style="flex: 1;text-align: center;">总计应收款</div>
                                <div class="lytable-borderright lytable-borderleft" style="width: 179px;"><span style="margin-left: 12px">— —</span></div>
                                <div class="lytable-borderright" style="width: 149px;"><span style="margin-left: 12px">— —</span></div>
                                <div style="width: 180px;"><span style="margin-left: 12px">{{formData.total_should_amount||0}}</span></div>
                            </div>
                            <div class="lyTableCustomAppend">
                                <div style="text-align: center;width: 300px;"><span style="color: red">*</span>美元汇率</div>
                                <div class="lytable-borderright lytable-borderleft" style="width: 220px;"><el-input-number v-model="formData.usdrate" placeholder="请输入美元汇率" controls-position="right" style="width: 100%" @change="handleChangeCalc"></el-input-number></div>
                                <div class="lytable-borderright" style="flex: 1;text-align: center;"><span style="margin-left: 12px">总计应收款折合美元</span></div>
                                <div style="width: 180px;"><span style="margin-left: 12px"></span>{{formData.total_yingshoumy||0}}</div>
                            </div>
                        </template>
                    </el-table>
                </el-form-item>
                <div class="ly-title">
                    实收金额
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="实收美元现钞(USD)" prop="shishou_musd">
                            <el-input-number v-model="formData.shishou_musd" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="美元折合K" prop="shishou_mzh">
                            <el-input v-model="formData.shishou_mzh" placeholder="系统计算" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="克瓦查现钞(K)" prop="shishou_kwc">
                            <el-input-number v-model="formData.shishou_kwc" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="本期新增欠款（K）" prop="shishou_bqk">
                            <el-input v-model="formData.shishou_bqk" placeholder="系统计算" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="银行存款K" prop="shishou_yhck">
                            <el-input-number v-model="formData.shishou_yhck" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="本期末欠款余额K" prop="shishou_mqky">
                            <el-input v-model="formData.shishou_mqky" placeholder="系统计算" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="MTN（K）" prop="shishou_mtn">
                            <el-input-number v-model="formData.shishou_mtn" placeholder="请输入" :precision="2" :controls="false" @change="handleChangeCalc"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="合计实收（折合K）" prop="heji_shihouk">
                            <el-input v-model="formData.heji_shihouk" placeholder="系统计算" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="其中美元（USD）" prop="shishou_musd">
                            <el-input v-model="formData.shishou_musd" placeholder="系统计算" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="克瓦查(K)" prop="heji_shihou_kwc">
                            <el-input v-model="formData.heji_shihou_kwc" placeholder="系统计算" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    备注
                </div>
                <el-form-item>
                    <el-input v-model="formData.remark" :rows="2" type="textarea" placeholder="请输入备注信息"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData(false)" :loading="loadingSave">确定</el-button>
                <el-button @click="submitData(true)" :loading="loadingSave">确定并打印</el-button>
            </template>
        </ly-dialog>
        <moduleGoodsList v-if="showGoodsList" ref="modulegoodslistFlag" @close = "showGoodsList = false"></moduleGoodsList>
        <moduleGoodsOutOrderPrint v-if="showPrint" ref="modulePrintFlag" @close = "showPrint = false"></moduleGoodsOutOrderPrint>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import Pagination from "@/components/Pagination";
    import {hasPermission, dateFormats, getStorage, lyParseParam2UrlParam} from "@/utils/util";
    import {psiGoodsoutorder,psiShopGetShopListQK,psiStore,psiStockgoods,psiCategory,psiGoodsoutorderAdd,psiGoodsoutorderExport} from '@/api/api'
    import useTableHight from '@/mixins/useTableHight';
    import { useRouter,useRoute } from 'vue-router'
    import LyDialog from "@/components/dialog/dialog";
    import LyTableSelect from "@/components/lyTableSelect";
    import moduleGoodsList from "./components/moudleGoodsList"
    import moduleGoodsOutOrderPrint from "./components/moduleGoodsOutOrderPrint"
    import {deepClone} from "../../utils/util";
    const route = useRoute()

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value)
    let loadingPage = ref(false)
    let loadingSave = ref(false)
    let isDialogShow = ref(false)
    let dialogTitle = ref("")
    let formData = ref({})
    let storeList = ref([])
    let categoryList = ref([])
    let shopList = ref([])
    let userName =getStorage("userName")
    let roleKey = getStorage("roleKey") || []
    let rules = reactive({
        shop: [
            {required: true, message: '请选择客户',trigger: 'blur'}
        ],
        out_time: [
            {required: true, message: '请选择出库日期',trigger: 'blur'}
        ],
        fahuoer: [
            {required: true, message: '请输入发货人',trigger: 'blur'}
        ]
    })

    function handleDialogClose(){
        isDialogShow.value = false
        formData.value = {}
    }
    let formInline = ref({
        page: 1,
        limit: 10,
    })

    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let pageparm = ref({
        page: 1,
        limit: 10,
        total: 0
    })
    let tableData = ref([])
    let timers = ref([])
    let typeList = ref([])
    let statusList = ref([
        {id:3,name:'已完成'},
        {id:1,name:'待审核'},
        {id:2,name:'已拒绝'},
    ])

    function getStoreList() {
        psiStore({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                storeList.value = res.data.data
            }
        })
    }
    function getShopList() {
        psiShopGetShopListQK({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                shopList.value = res.data
            }
        })
    }
    function getCategoryList() {
        psiCategory({page:1,limit:999}).then(res=>{
            if(res.code == 2000){
                categoryList.value = res.data.data
            }
        })
    }

    let lymanualSelect = ref(null)
    function handleManualSelectClick(){
        if(formData.value.shop == "" || formData.value.shop==undefined){
            ElMessage.warning("请选择客户")
            return
        }
        lymanualSelect.value.handleChooseClick()
    }
    function handleManualConfirmClick(items){
        //去重赋值
        if(items.length>0){
            let newGoodsList = formData.value.goodslist
            for(var i = 0; i < items.length; i ++){
                //如果不存在返回值为-1(去重)
                if(newGoodsList.findIndex(item1=>item1.goodsinfo.id === items[i].id ) == -1){
                    formData.value.goodslist.push({
                        goodsinfo:items[i],
                        nums:items[i].stock_nums,
                        total_mount:items[i].stock_nums*items[i].goodsinfo.price,
                        remark:""
                    })
                }
            }
            handleChangeCalc()
        }
    }
    function getGoodsApi() {
        return psiStockgoods
    }
    //触发自动计算规则
    function handleShopSelectChange(e) {
        shopList.value.forEach(item=>{
            if(item.id == e){
                formData.value.shangqimqk = Number(item.qk)
            }
        })
        handleChangeCalc()
    }
    function handleNumsChangeCalc(row,index){
        formData.value.goodslist[index].total_mount = row.nums * row.goodsinfo.goodsinfo.price
        handleChangeCalc()
    }
    //保留小数后两位（不四舍五入）
    function toFixed2(value) {
        if(!!value){
            // 截取当前数据到小数点后三位(保留后两位小数不四舍五入)
            let tempVal = parseFloat(value).toFixed(3)
            let realVal = tempVal.substring(0, tempVal.length - 1)
            return Number(realVal)
        }else{
            return 0
        }

    }
    function handleChangeCalc(){
        formData.value.total_nums = formData.value.goodslist.reduce((prev,item) =>{
            return item.nums + prev;
        },0)
        formData.value.total_amount = formData.value.goodslist.reduce((prev,item) =>{
            return Number(item.total_mount) + prev;
        },0)
        formData.value.total_should_amount = toFixed2(formData.value.total_amount + formData.value.shangqimqk)
        formData.value.total_yingshoumy = toFixed2(Number(formData.value.total_should_amount) / formData.value.usdrate)
        formData.value.shishou_mzh = toFixed2(Number(formData.value.shishou_musd) * formData.value.usdrate)
        formData.value.heji_shihouk = toFixed2(Number(formData.value.shishou_mzh) + Number(formData.value.shishou_kwc) + Number(formData.value.shishou_mtn) + Number(formData.value.shishou_yhck))
        formData.value.heji_shihou_kwc = toFixed2(formData.value.heji_shihouk - formData.value.shishou_mzh)
        formData.value.shishou_bqk = toFixed2(formData.value.total_amount - formData.value.heji_shihouk)
        formData.value.shishou_mqky = toFixed2(Number(formData.value.shangqimqk) + Number(formData.value.shishou_bqk))
    }
    //提交保存数据
    let rulesForm = ref(null)
    function submitData(isprint=false){
        rulesForm.value.validate(obj=>{
            if(obj) {
                loadingSave.value=true
                let param = {
                    ...formData.value
                }
                var goodslist = param.goodslist
                var ispass = true
                for(var i =0;i<goodslist.length;i++){
                    if(goodslist[i].nums ==null){
                        ispass = false
                        break
                    }
                }
                if(!ispass){
                    ElMessage.warning("必填内容不能为空，请填写后再提交")
                    loadingSave.value=false
                    return
                }
                psiGoodsoutorderAdd(param).then(res=>{
                    loadingSave.value=false
                    if(res.code ==2000) {
                        ElMessage.success(res.msg)
                        handleDialogClose()
                        getData()
                        if(isprint){
                            showPrint.value = true
                            nextTick(()=>{
                                modulePrintFlag.value.moduleFn(res.data,"打印")
                            })
                        }
                    } else {
                       ElMessage.warning(res.msg)
                    }
                })

            }
        })
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }
    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.value.page-1)*pageparm.value.limit + $index +1
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
     /** 添加表格操作 */
    function rowDel(row, index){
         formData.value.goodslist.splice(index, 1)
         handleChangeCalc()
    }
    let showGoodsList = ref(false)
    let modulegoodslistFlag =ref(null)
    let showPrint = ref(false)
    let modulePrintFlag =ref(null)
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline.value = {
                page:1,
                limit: 10
            }
            pageparm.value={
                page: 1,
                limit: 10,
                total: 0
            }
            timers.value = []
            search()
        }else if(flag == "goodslist"){
            showGoodsList.value = true
            nextTick(()=>{
                modulegoodslistFlag.value.moduleFn(row.id,"明细")
            })
        }else if(flag == "print"){
            showPrint.value = true
            nextTick(()=>{
                modulePrintFlag.value.moduleFn(row,"打印")
            })
        }
        else if(flag === 'add'){
            getCategoryList()
            getStoreList()
            getShopList()
            dialogTitle = "添加商品出库单"
            formData.value.fahuoer = userName
            formData.value.out_time = dateFormats(new Date(),'yyyy-MM-dd hh:mm:ss')
            formData.value.goodslist = []
            formData.value.shishou_kwc = null
            formData.value.heji_shihou_kwc = null
            formData.value.shishou_musd = 0
            formData.value.shishou_mzh = 0
            formData.value.shishou_kwc = 0
            formData.value.shishou_mtn = 0
            formData.value.shishou_yhck = 0
            isDialogShow.value = true
        }
    }

    function callFather(parm) {
        formInline.value.page = parm.page
        formInline.value.limit = parm.limit
        getData()
    }
    function search() {
        formInline.value.page = 1
        formInline.value.limit = 10
        getData()
    }

    //获取列表
    async function getData(){
        loadingPage.value = true
        psiGoodsoutorder(formInline.value).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.data
                pageparm.value.page = res.data.page;
                pageparm.value.limit = res.data.limit;
                pageparm.value.total = res.data.total;
            }
        })
    }
    function timeChange(val){
        if (val) {
            formInline.value.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
            formInline.value.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
        } else {
            formInline.value.beginAt = null
            formInline.value.endAt = null
        }
        search()
    }
    // // 下载文件URL文件
    // function downloadFileURL(url) {
    //     var iframe =document.createElement("iframe")
    //     iframe.style.display ="none";
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    // }
    //
    // function exportData(){
    //     psiGoodsinstoreExport(formInline).then(res => {
    //          if(res.code ==2000) {
    //              downloadFileURL(res.data.data)
    //          }else{
    //               ElMessage.warning(res.msg)
    //          }
    //      })
    //
    // }
    //excel文件流下载
    function downloadExcelBlob(res) {
        let fileName = new Date().getTime() +".xlsx"
        let dispositionStr = res.headers["content-disposition"];
        if (dispositionStr == null || dispositionStr === "") {

        }else{
            // 获取文件名
            let dispositionArr = dispositionStr.split(";");
            fileName = decodeURIComponent(dispositionArr[1]);
            fileName = fileName.split("=")[1];
        }
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('导出成功')
    }
    //导出
    function exportData(row) {
        let params = {}
        let urlparams = lyParseParam2UrlParam(formInline.value)
        urlparams = !!urlparams?"?"+urlparams:''
        if(ids.value.length>0){
            params['ids'] = ids.value
        }
        loadingPage.value = true
        psiGoodsoutorderExport(urlparams,params).then(res => {
            loadingPage.value = false
            downloadExcelBlob(res)
        })
    }

    onMounted(()=>{
        getShopList()
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
    .lyTableCustomAppend{
        display: flex;
        align-items: center;
        border-bottom:var(--el-table-border);
    }
    .lytable-borderright{
        border-right: var(--el-table-border);
    }
    .lytable-borderleft{
        border-left: var(--el-table-border);
    }
</style>