<template>
    <div>
        <ly-crud ref="lycrud"  v-bind="crudConfig" ></ly-crud>
    </div>
</template>

<script lang="jsx">
    import {psiGoods,psiGoodsAdd,psiGoodsDelete,psiGoodsEdit,psiCategory} from '@/api/api'
    import LyCrud from "@/components/lycrud";
    import templateData from "@/components/dict/crudTemplateData"
    import { h,resolveComponent } from 'vue';
    export default {
        name: "psiGoods",
        components: {LyCrud},
        data(){
            let categoryList = this.getPsiCategoryList()//使data中变量能调用
            return{
                defaultImg:require('../../assets/img/avatar.jpg'),
                //crud配置
                crudConfig:{
                    //crud请求方法配置
                    crudRequest:{
                        add:psiGoodsAdd,
                        del:psiGoodsDelete,
                        edit:psiGoodsEdit,
                        search:psiGoods,
                    },
                    //搜索栏目配置
                    searchBar:{
                        showSearchBar:true,//显示搜索栏目
                        searchColums:[
                            {label:'商品货号',type:'input',prop:'no',width:200,maxlength:60,placeholder:'请输入商品货号'},
                        ]
                    },
                    //显示分页
                    showPagination:true,
                    //分页配置
                    pageparams: {
                        limit: 10,//每页显示的条数(默认每页显示10条)//非必填
                        //pageSizes:[10,20,30,40,50,200],//非必填
                        // layout:'total, sizes, prev, pager, next, jumper',//非必填
                    },
                    //crud按钮配置
                    rowHandle:{
                        width: 180,//操作列宽度
                        fixed:"right",//固定操作列在右侧
                        permission:{//增删改查按钮权限控制（是否显示）
                            add:this.hasPermission(this.$options.name,'Create'),//bool型 this.$options.name获取当前组件的name本例为userManageCrud
                            del:false,
                            edit:this.hasPermission(this.$options.name,'Update'),
                            search:this.hasPermission(this.$options.name,'Search'),
                            detail:false,
                        }
                    },
                    //crud弹窗属性
                    formOptions: {
                        width:'45%',//dialog弹窗宽度：类型：百分比或字符串
                        gutter: 20, // Layout布局栅格间隔
                    },
                    //crud表格属性
                    tableOptions:{
                        border:true,
                        showHeader:true,
                    },
                    showSelectable:true,//表格显示复选项框
                    //table表头列
                    tableColumns:[
                         // {label:'用户头像',type:'image',prop:'avatar',minWidth:'60',sortable: false,hidden:false,render:(row)=>{
                         //     let elImage = resolveComponent('el-image')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
                         //     return h(elImage,{
                         //          src:row.avatar ? row.avatar : this.defaultImg,
                         //          previewSrcList:[row.avatar ? row.avatar : this.defaultImg],//开启预览，原preview-src-list属性在h渲染方法中，-后字母大写代替即可：previewSrcList
                         //          previewTeleported:true,//插入至body元素上
                         //          style:"width: 30px;height: 30px",
                         //     },)
                         //  }
                         // },
                        {label:'ID',prop:'id',type:'input',minWidth:'100',sortable: false,hidden:true,
                            form:{
                                 //表单属性
                                 span:24,
                                 hidden:true,//编辑时隐藏，添加时去除
                             }
                        },
                         {label:'品类',prop:'category',type:'select',minWidth:'110',sortable: false,hidden:false,
                             render:(row)=>{
                                //table显示样子
                                let namerow =  categoryList.filter(item=>{
                                    return item.value === row.category
                                })
                                return namerow.length>0?namerow[0].label:'-'
                            },
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '品类必填项' }],
                                placeholder: '请选择品类',
                                options:categoryList,
                            }
                         },
                         {label:'商品货号',prop:'no',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '商品货号必填项' }],
                                placeholder: '请输入商品货号',
                            }
                         },
                        {label:'商品图',type:'image-avatar',prop:'image',minWidth:'60',sortable: false,hidden:false,
                            render:(row)=>{
                                //jsx语法
                                return <el-image src={row.image} style="width: 30px;height: 30px" preview-teleported={true} preview-srcList={[row.image]}></el-image>
                            },
                            form:{
                                //表单属性
                                span:24,
                                // width:80,头像大小默认80px
                            }

                         },
                        {label:'规格',prop:'spec',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '规格必填项' }],
                                placeholder: '请输入规格',
                            }
                         },
                         {label:'价格',prop:'price',type:'price',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                hidden:true,
                                span:24,
                                rules: [{ required: false, message: '价格必填项' }],
                                placeholder: '请输入价格',
                            }
                         },
                        {label:'数量单位',prop:'unit',type:'select',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '数量单位必填项' }],
                                placeholder: '请选择数量单位',
                                options:templateData.GOODS_UNIT,
                            }
                         },
                        {label:'创建时间',prop:'create_datetime',minWidth:'150',sortable: false,hidden:false},
                    ],
                },

            }
        },
        methods:{
            setFull(){
                this.$refs.lycrud.setFull()
            },
            //自定义操作列按钮方法
            handleClick(row,flag){
            },
            getPsiCategoryList(){
                let slist = []
                psiCategory({page:1,limit:999}).then(res=>{
                    if(res.code == 2000) {
                        res.data.data.forEach(item=>{
                            slist.push({
                                value:item.id,
                                label:item.name
                            })
                        })
                    }
                })
                return slist
            },
        },
    }
</script>

<style scoped>

</style>